import React from "react";
import { Modal, ModalTitle, Button, RegularField } from "shared/components";
import { Formik, Field } from "formik";
import { func, object, string } from "prop-types";

const GeneratorModal = ({
  isOpen,
  setIsOpen,
  updateInstagramIdeas,
  userId,
  instagramDetails,
  generateChatGptResponse,
  getInstagramPostIdea,
}) => {
  const closeModal = () => setIsOpen(false);
  const handleOnSubmit = async (values) => {
    console.log(values);
  };

  const handleSaveIdeas = async (idea) => {
    await updateInstagramIdeas(userId, idea);
  };

  const handleGenerateIdeas = async (idea) => {
    await generateChatGptResponse(idea, userId);
    await getInstagramPostIdea(userId, "generated");
    closeModal();
  };

  return (
    <>
      <Modal
        className="instagram-bio-edit"
        opened={isOpen}
        onClose={() => closeModal()}
      >
        <div className="user-type-modal">
          <ul className="user-type-modal--left">
            <Formik
              onSubmit={handleOnSubmit}
              initialValues={{
                ideas: instagramDetails.ideas || "Jeszcze nie ma opisu",
              }}
              enableReinitialize
            >
              {({ handleSubmit, errors, values }) => (
                <>
                  <ModalTitle title={`Generator pomysłów`} />
                  <>
                    <Field
                      component={RegularField}
                      errors={errors}
                      name="ideas"
                      type="textarea"
                      customBorder="customize-field"
                    />
                    <div className="user-type-modal__buttons">
                      <Button
                        label="Anuluj"
                        className="btn--frame-dark"
                        onClick={() => closeModal()}
                      />
                      <Button
                        label="Zapisz"
                        className="btn--frame-dark"
                        onClick={() => handleSaveIdeas(values.ideas)}
                      />
                      <Button
                        label="Generuj"
                        className="btn--frame-dark"
                        onClick={() => handleGenerateIdeas(values.ideas)}
                      />
                    </div>
                  </>
                </>
              )}
            </Formik>
          </ul>
        </div>
      </Modal>
    </>
  );
};

export default GeneratorModal;

GeneratorModal.propTypes = {
  setManageModal: func.isRequired,
  updateInstagramIdeas: func.isRequired,
  instagramDetails: object.isRequired,
  setAccountType: func.isRequired,
  getInstagramPostIdea: func.isRequired,
  userId: string.isRequired,
};
