import { deleteInstagramPostTime as deleteInstagramPostTimeConnector } from "shared/connectors/instagramConnector";
import { NOTIFY_TYPE, NOTIFY_LABEL } from "shared/consts";
import { Notify } from "shared/components";
import { toast } from "react-toastify";
import { INSTAGRAM } from "store/types";

// Inicjalizacja akcji
const init = () => ({
  type: INSTAGRAM.DELETE_INSTAGRAM_POST_TIME_INIT,
});

// Sukces akcji
const success = (postTimeId) => ({
  type: INSTAGRAM.DELETE_INSTAGRAM_POST_TIME_SUCCESS,
  payload: {
    postTimeId,
  },
});

const failure = () => ({
  type: INSTAGRAM.DELETE_INSTAGRAM_POST_TIME_FAILURE,
});

const deleteInstagramPostTime = (postTimeId) => async (dispatch) => {
  dispatch(init());
  try {
    await deleteInstagramPostTimeConnector(postTimeId);
    dispatch(success(postTimeId));
    toast(
      <Notify
        label={NOTIFY_LABEL.INSTAGRAM_POST_TIME_DELETED}
        type={NOTIFY_TYPE.CHECKED}
        color="green"
      />
    );
    return true;
  } catch (err) {
    dispatch(failure());
    toast(
      <Notify
        label={NOTIFY_LABEL.INSTAGRAM_POST_TIME_DELETE_WARNING}
        type={NOTIFY_TYPE.WARNING}
        color="red"
      />
    );
    return err.response ? err.response.data : err.message;
  }
};

export default deleteInstagramPostTime;
