import { addInstagramAccount } from "shared/connectors/instagramConnector";
import { NOTIFY_TYPE, NOTIFY_LABEL } from "shared/consts";
import { Notify } from "shared/components";
import { toast } from "react-toastify";
import { INSTAGRAM } from "store/types";

const init = () => ({
  type: INSTAGRAM.GET_INSTAGRAM_ACCOUNTS_INIT,
});

const success = (data) => ({
  type: INSTAGRAM.GET_INSTAGRAM_ACCOUNTS_SUCCESS,
  payload: {
    data,
  },
});
const failure = () => ({
  type: INSTAGRAM.GET_INSTAGRAM_ACCOUNTS_FAILURE,
});
export const addInstagramAccountAction =
  (token, username) => async (dispatch) => {
    dispatch(init());
    try {
      const response = await addInstagramAccount(token, username);
      dispatch(success(response.data));
      toast(
        <Notify
          label={NOTIFY_LABEL.INSTAGRAM_ACCOUNT_ADDED}
          type={NOTIFY_TYPE.CHECKED}
          color="green"
        />
      );
      return response.data;
    } catch (err) {
      dispatch(failure());
      toast(
        <Notify
          label={NOTIFY_LABEL.INSTAGRAM_ACCOUNT_ADD_WARNING}
          type={NOTIFY_TYPE.WARNING}
          color="red"
        />
      );
      return err.response.data;
    }
  };
