import { useState } from "react";
import { MyAccountBadge, SVG } from "shared/components";
import { string, func } from "prop-types";
import { CloseClickOutside } from "shared/utils";
import { ROUTES, SVG_TYPE, USER_ROLE, USER_TYPE } from "shared/consts";
import { useHistory } from "react-router-dom";

const MyAccount = ({
  className,
  logout,
  authorizedUser,
  categoriesAndSubcategories,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isExpandShop, setIsExpandShop] = useState(false);
  // const [isExpandCrafts, setIsExpandCrafts] = useState(false);
  const isExpandShopClass = isExpandShop ? "sub-list--active" : "";
  // const isExpandShopIconClass = isExpandShop ? "list--item__icon--active" : "";
  // const isExpandCraftsIconClass = isExpandCrafts ? "sub-list--active" : "";
  const isAdmin = authorizedUser?.role === USER_ROLE.ADMIN;
  const isCraftsman = authorizedUser?.type === USER_TYPE.CRAFTSMAN;
  const isPartner = authorizedUser?.type === USER_TYPE.PARTNER;
  const history = useHistory();
  const handleOpenMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = async () => {
    const response = await logout();
    response && history.push(ROUTES.HOME_PAGE);
  };

  // const handleExpandShop = () => {
  //   setIsExpandShop(!isExpandShop);
  // };
  // const handleExpandCrafts = () => {
  //   setIsExpandCrafts(!isExpandCrafts);
  // };
  const handleClickOutside = () => {
    setIsMenuOpen(false);
    setIsExpandShop(false);
  };

  return (
    <div className={`my-account ${className}`}>
      <MyAccountBadge
        authorizedUser={authorizedUser}
        isMenuOpen={isMenuOpen}
        onClick={() => handleOpenMenu()}
        chevrone
      />
      <div className="settings-dots">
        <div className="settings-dots__wrapper">
          <div className="settings-dots__content">
            <CloseClickOutside
              className="settings-dots__content"
              onClose={() => handleClickOutside()}
            >
              <ul className={`list view ${isMenuOpen ? "view--active" : ""}`}>
                <li className="list--item__wrapper">
                  {/* <li className="list--item list--item--shop">
                    <div
                      className="list--item__onclick"
                      onClick={() => history.push(ROUTES.SHOP)}
                    />
                    <div className="list--item--name"> Sklep </div>
                    <div onClick={() => handleExpandShop()}>
                      <SVG
                        className={`list--item__icon ${isExpandShopIconClass}`}
                        type={SVG_TYPE.CHEVRONE}
                      />
                    </div>
                  </li> */}
                  <ul className={`sub-list ${isExpandShopClass}`}>
                    {isCraftsman && isPartner && (
                      <>
                        <li
                          className="list--item"
                          onClick={() => history.push(ROUTES.MY_ADVERTISEMENTS)}
                        >
                          {" "}
                          Zarządzanie produktami
                        </li>
                        <li
                          className="list--item"
                          onClick={() => history.push(ROUTES.ADD_PRODUCT_PAGE)}
                        >
                          {" "}
                          Dodaj produkt
                        </li>
                      </>
                    )}
                    {isAdmin && (
                      <li
                        className="list--item"
                        onClick={() => history.push(ROUTES.DELIVERY_PAGE)}
                      >
                        {" "}
                        Dodaj metody wysyłki
                      </li>
                    )}
                    {categoriesAndSubcategories?.map((category) => (
                      <li
                        className="list--item"
                        onClick={() => history.push(ROUTES.SHOP)}
                      >
                        {" "}
                        {category.name}
                      </li>
                    ))}
                  </ul>
                </li>

                {isAdmin && (
                  <li className="list--item__wrapper">
                    {/* <li className="list--item ">
                      <div
                        className="list--item__onclick"
                        onClick={() => history.push(ROUTES.CRAFCATS)}
                      />
                      <div className="list--item--name"> Rzemiosło </div>
                      <div onClick={() => handleExpandCrafts()}>
                        <SVG
                          className={`list--item__icon ${isExpandCraftsIconClass}`}
                          type={SVG_TYPE.CHEVRONE}
                        />
                      </div>
                    </li> */}
                    {/* <ul className={`sub-list ${isExpandCraftsIconClass}`}>
                      <li
                        className="list--item"
                        onClick={() => history.push(ROUTES.CATEGORY)}
                      >
                        Zarządzanie kategoriami
                      </li>
                    </ul> */}
                  </li>
                )}
                <li
                  className="list--item sub-list__item"
                  onClick={() => history.push(ROUTES.USER_LIST)}
                >
                  Instagram <SVG type={SVG_TYPE.ACCOUNT} />
                </li>
                <li
                  className="list--item sub-list__item"
                  onClick={() => handleLogout()}
                >
                  Wyloguj się <SVG type={SVG_TYPE.LOGOUT} />{" "}
                </li>
              </ul>
            </CloseClickOutside>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyAccount;

MyAccount.propTypes = {
  handleOpenModal: string,
  logout: func,
};
