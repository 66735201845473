import { updateInstagramPostStatus as updateInstagramPostStatusConnector } from "shared/connectors/instagramConnector";
import { NOTIFY_TYPE, NOTIFY_LABEL } from "shared/consts";
import { Notify } from "shared/components";
import { toast } from "react-toastify";
import { INSTAGRAM } from "store/types";

const updateInit = () => ({
  type: INSTAGRAM.UPDATE_INSTAGRAM_POST_STATUS_INIT,
});

const updateSuccess = (postId, status) => ({
  type: INSTAGRAM.UPDATE_INSTAGRAM_POST_STATUS_SUCCESS,
  payload: {
    postId,
    status,
  },
});

const updateFailure = () => ({
  type: INSTAGRAM.UPDATE_INSTAGRAM_POST_STATUS_FAILURE,
});

const updateInstagramPostStatus = (postId, status) => async (dispatch) => {
  dispatch(updateInit());
  try {
    await updateInstagramPostStatusConnector(postId, { status });
    dispatch(updateSuccess(postId, status));
    toast(
      <Notify
        label={`${NOTIFY_LABEL.INSTAGRAM_POST_STATUS_CHANGE}${status}`}
        type={NOTIFY_TYPE.CHECKED}
        color="green"
      />
    );
    return true;
  } catch (err) {
    dispatch(updateFailure());
    toast(
      <Notify
        label={`Aktualizacja statusu postu nie powiodła się`}
        type={NOTIFY_TYPE.WARNING}
        color="red"
      />
    );
    return err.response ? err.response.data : err.message;
  }
};

export default updateInstagramPostStatus;
