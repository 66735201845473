import { bool, func } from "prop-types";
import React from "react";
import { NavLink } from "react-router-dom";
import { Button } from "shared/components";
import { ROUTES, LOGIN_MANAGE_TYPE, SVG_TYPE } from "shared/consts";
import { MyAccount } from "shared/components/menu-top/components";

const menuLists = [
  {
    label: "Strona główna",
    routes: ROUTES.HOME_PAGE,
  },
  // {
  //   label: "Rzemiosło",
  //   routes: ROUTES.SHOP,
  // },
  // {
  //   label: "Sklep",
  //   routes: ROUTES.SHOP,
  // },
  // {
  //   label: "Forum",
  //   routes: ROUTES.FORUM,
  // },
  // {
  //   label: "Kontakt",
  //   routes: ROUTES.CONTACT,
  // },
];

const Desktop = ({ handleOpenModal, authorizedUser, logout, loadingLogin }) => {
  const isUserLogged = localStorage.getItem("isUserLogged");
  return (
    <div className="menu-top__content menu-top__content--desktop">
      <ul className="menu-top__list">
        {menuLists.map(({ label, routes }) => (
          <NavLink
            className="menu-top__item"
            activeClassName="menu-top__item--active"
            exact
            to={routes}
          >
            {label}
          </NavLink>
        ))}
        {/* {loadingLogin ? (
          <div className="menu-top-skeleton">
            <SVG type={SVG_TYPE.LOADING} />
          </div>
        ) : ( */}
        <>
          {!isUserLogged ? (
            <NavLink
              className="menu-top__item menu-top__item--login"
              activeClassName="menu-top__item--active"
              exact
              to={ROUTES.HOME_PAGE}
              onClick={() => handleOpenModal(LOGIN_MANAGE_TYPE.LOGIN)}
            >
              <Button
                className={`${
                  loadingLogin ? "btn--before" : "btn--after"
                } btn--normal-stroke`}
                iconType={`${loadingLogin ? SVG_TYPE.LOADING : ""}`}
                label={`${loadingLogin ? "" : "Zaloguj się"}`}
              />
            </NavLink>
          ) : (
            <MyAccount
              authorizedUser={authorizedUser}
              logout={logout}
              className="menu-top--account"
            />
          )}
        </>
        {/* )} */}
      </ul>
    </div>
  );
};

export default Desktop;

Desktop.propTypes = {
  handleOpenModal: func.isRequired,
  logout: func.isRequired,
  loadingLogin: bool.isRequired,
};
