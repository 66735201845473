import moment from "moment";
import EmptyImage from "assets/files/empty.jpg";
import { SVG } from "shared/components";
import { SVG_TYPE } from "shared/consts";
import { object, func, bool } from "prop-types";

const InstagramProfile = ({
  refreshInstagramAccountAction,
  profile,
  setManageModal,
  isActive,
  getInstagramDetails,
}) => {
  const handleOpenIdeas = async () => {
    await getInstagramDetails(profile.user_id);
    setManageModal({ isModalOpen: "postIdeas", user_id: profile.user_id });
  };
  const handleOpenTags = async () => {
    setManageModal({
      isModalOpen: "postTags",
      user_id: profile.user_id,
      username: profile.username,
    });
  };
  const handleOpenImagePrompt = async () => {
    setManageModal({
      isModalOpen: "imagePrompt",
      user_id: profile.user_id,
      username: profile.username,
    });
  };

  return (
    <div className={`instagram-profile ${isActive ? "active" : ""}`}>
      <div className="instagram-profile__image-wrapper">
        <img
          src={profile.profile_picture_url || EmptyImage}
          alt={`${profile.username}__image`}
        />
      </div>
      <div className="instagram-profile__last-update">
        {moment(profile.updated_at).format("DD-MM-YYYY HH:mm")}
      </div>
      <div
        className="instagram-profile__refresh"
        onClick={async () => {
          await refreshInstagramAccountAction(profile.user_id);
        }}
      >
        <SVG type={SVG_TYPE.REFRESH} />
      </div>
      <div className="instagram-profile__content">
        <div className="instagram-profile__content--top">
          <div className="instagram-profile__name">{profile.username}</div>
          <div className="instagram-profile__counter">
            <div className="instagram-profile__counter-label">Followersów</div>
            <div className="instagram-profile__counter-value">
              {profile.followers_count}
            </div>
          </div>
          <div className="instagram-profile__counter">
            <div className="instagram-profile__counter-label">Postów</div>
            <div className="instagram-profile__counter-value">
              {profile.media_count}
            </div>
          </div>
          <div className="instagram-profile__counter">
            <div
              className="instagram-profile__counter-label active"
              onClick={() => handleOpenIdeas()}
            >
              Pomysły
            </div>
          </div>
          <div className="instagram-profile__counter">
            <div
              className="instagram-profile__counter-label active"
              onClick={() => handleOpenTags()}
            >
              Tagi
            </div>
          </div>
          <div className="instagram-profile__counter">
            <div
              className="instagram-profile__counter-label active"
              onClick={() => handleOpenImagePrompt()}
            >
              Image prompt
            </div>
          </div>
        </div>
        <div className="instagram-profile__content--bottom">
          <div className="instagram-profile__bio">
            {profile.biography || "brak opisu"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstagramProfile;
InstagramProfile.propTypes = {
  refreshInstagramAccountAction: func.isRequired,
  setManageModal: func.isRequired,
  getInstagramDetails: func.isRequired,
  profile: object.isRequired,
  isActive: bool.isRequired,
};
