import { createSelector } from "reselect";
// import { RootState } from 'Store/Reducers/RootReducer';

const getChatGptStore = (state) => state.chatGptStore;
const getChatGptResponse = createSelector(
  getChatGptStore,
  (state) => state.chatResponse
);

export { getChatGptResponse };
