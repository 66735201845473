import { updateInstagramBio as updateInstagramBioConnector } from "shared/connectors/instagramConnector"; // Import funkcji konektora
import { NOTIFY_TYPE, NOTIFY_LABEL } from "shared/consts";
import { Notify } from "shared/components";
import { toast } from "react-toastify";
import { INSTAGRAM } from "store/types";

const init = () => ({
  type: INSTAGRAM.UPDATE_INSTAGRAM_BIO_INIT,
});

const success = (data) => ({
  type: INSTAGRAM.UPDATE_INSTAGRAM_BIO_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: INSTAGRAM.UPDATE_INSTAGRAM_BIO_FAILURE,
});

const updateInstagramBio = (userId, biography) => async (dispatch) => {
  dispatch(init());
  try {
    const response = await updateInstagramBioConnector(userId, biography);
    dispatch(success(response.data));
    toast(
      <Notify
        label={NOTIFY_LABEL.INSTAGRAM_BIO_UPDATED}
        type={NOTIFY_TYPE.CHECKED}
        color="green"
      />
    );
    return response.data;
  } catch (err) {
    dispatch(failure());
    toast(
      <Notify
        label={NOTIFY_LABEL.INSTAGRAM_BIO_UPDATE_WARNING}
        type={NOTIFY_TYPE.WARNING}
        color="red"
      />
    );
    return err.response?.data || "Error";
  }
};

export default updateInstagramBio;
