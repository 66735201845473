import { getAccountInfo } from "shared/connectors/instagramConnector";
import { FORUM } from "store/types";
import { NOTIFY_TYPE, NOTIFY_LABEL } from "shared/consts";
import { Notify } from "shared/components";
import { toast } from "react-toastify";

const init = () => ({
  type: FORUM.GET_FORUM_QUESTIONS_INIT,
});

export const success = (data) => ({
  type: FORUM.GET_FORUM_QUESTIONS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: FORUM.GET_FORUM_QUESTIONS_FAILURE,
});

const getInstagramAccountInfo = (token) => async (dispatch) => {
  dispatch(init());
  console.log(token, "poka");
  try {
    const response = await getAccountInfo(token);
    dispatch(success(response.data.ForumQuestions));
    toast(
      <Notify
        label={NOTIFY_LABEL.INSTAGRAM_ACCOUNT_ADD}
        type={NOTIFY_TYPE.CHECKED}
        color="green"
      />
    );
    return response.data;
  } catch (err) {
    dispatch(failure());
    toast(
      <Notify
        label={NOTIFY_LABEL.INSTAGRAM_ACCOUNT_WARNING}
        type={NOTIFY_TYPE.WARNING}
        color="red"
      />
    );
    return err.response.data;
  }
};

export default getInstagramAccountInfo;
