import React from "react";
import { Modal, Button } from "shared/components";
import { func, object, string } from "prop-types";

const GeneratorModal = ({ isOpen, onClose }) => {
  const handleSubmit = () => {
    console.log("1");
  };

  return (
    <>
      <Modal
        className="instagram-bio-edit"
        opened={isOpen}
        onClose={() => onClose()}
      >
        <div className="user-type-modal">
          <ul className="user-type-modal--left">
            <div className="modal--h3">
              Jesteś pewien że chcesz udostępnić ten post teraz?
            </div>
            <div className="user-type-modal__buttons">
              <Button
                label="Anuluj"
                className="btn--frame-dark"
                onClick={() => onClose()}
              />
              <Button
                className="btn--default"
                label="Potwierdź"
                onClick={() => handleSubmit()}
              />
            </div>
          </ul>
        </div>
      </Modal>
    </>
  );
};

export default GeneratorModal;

GeneratorModal.propTypes = {
  setManageModal: func.isRequired,
  updateInstagramIdeas: func.isRequired,
  instagramDetails: object.isRequired,
  setAccountType: func.isRequired,
  getInstagramPostIdea: func.isRequired,
  userId: string.isRequired,
};
