const CHAT_GPT = {
  GENERATE_RESPONSE_INIT: "GENERATE_RESPONSE_INIT",
  GENERATE_RESPONSE_SUCCESS: "GENERATE_RESPONSE_SUCCESS",
  GENERATE_RESPONSE_FAILURE: "GENERATE_RESPONSE_FAILURE",

  GENERATE_TAGS_INIT: "GENERATE_TAGS_INIT",
  GENERATE_TAGS_SUCCESS: "GENERATE_TAGS_SUCCESS",
  GENERATE_TAGS_FAILURE: "GENERATE_TAGS_FAILURE",
};

export default CHAT_GPT;
