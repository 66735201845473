import { updateInstagramImagePrompt as updateInstagramImagePromptConnector } from "shared/connectors/instagramConnector"; // Import funkcji konektora
import { NOTIFY_TYPE, NOTIFY_LABEL } from "shared/consts";
import { Notify } from "shared/components";
import { toast } from "react-toastify";
import { INSTAGRAM } from "store/types";

const init = () => ({
  type: INSTAGRAM.UPDATE_INSTAGRAM_IMAGE_PROMPT_INIT,
});

const success = (data) => ({
  type: INSTAGRAM.UPDATE_INSTAGRAM_IMAGE_PROMPT_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: INSTAGRAM.UPDATE_INSTAGRAM_IMAGE_PROMPT_FAILURE,
});

const updateInstagramImagePrompt = (userId, ideas) => async (dispatch) => {
  dispatch(init());
  try {
    const response = await updateInstagramImagePromptConnector(userId, ideas);
    dispatch(success(response.data));
    toast(
      <Notify
        label={NOTIFY_LABEL.INSTAGRAM_IMAGE_PROMPT_UPDATED}
        type={NOTIFY_TYPE.CHECKED}
        color="green"
      />
    );
    return response.data;
  } catch (err) {
    dispatch(failure());
    toast(
      <Notify
        label={NOTIFY_LABEL.INSTAGRAM_IMAGE_UPDATE_WARNING}
        type={NOTIFY_TYPE.WARNING}
        color="red"
      />
    );
    return err.response?.data || "Error";
  }
};

export default updateInstagramImagePrompt;
