import React, { useState } from "react";
import { func } from "prop-types";
import {
  LOGIN_MANAGE_TYPE,
  VALIDATION_MESSAGES,
  SVG_TYPE,
} from "shared/consts";
import { RegularField, Button } from "shared/components";
import { ModalTitle } from "shared/components/modal/components";
import { Field, Formik } from "formik";
import { object, string, ref } from "yup";
import SVG from "shared/components/svg";

const RegistrationForm = ({ changeModalType, registerUser }) => {
  const { EMAIL, REQUIRED_FIELD } = VALIDATION_MESSAGES;
  const { LOGIN } = LOGIN_MANAGE_TYPE;
  const [isMailSent, setIsMailSent] = useState();
  const [isLoading, setIsLoading] = useState();

  const validationForm = () =>
    object().shape({
      email: string().email(EMAIL).required(REQUIRED_FIELD),
      password: string().required(REQUIRED_FIELD),
      passwordRepeat: string()
        .required(REQUIRED_FIELD)
        .oneOf([ref("password"), null], "Niezgodne hasła"),
      userName: string().required(REQUIRED_FIELD),
    });

  const submitForm = async (values, actions) => {
    setIsLoading(true);
    const response = await registerUser(values);
    if (response.email_exist && response.user_exist) {
      actions.setErrors({
        email: "Email zajęty",
        userName: "Nazwa użytkownika zajęta",
      });
      setIsLoading(false);
    } else if (response?.email_exist) {
      setIsLoading(false);
      actions.setErrors({ email: "Email zajęty" });
    } else if (response?.user_exist) {
      setIsLoading(false);
      actions.setErrors({ userName: "Nazwa użytkownika zajęta" });
    }

    setIsMailSent(response.user.email);
    setIsLoading(false);
  };

  return (
    <>
      {!isMailSent ? (
        <>
          <ModalTitle title="Rejestracja" />
          <Formik
            initialValues={{
              email: "",
              password: "",
              userName: "",
              passwordRepeat: "",
            }}
            // initialValues={{
            //   email: 'wilczewskikacper@gmail.com',
            //   password: 'asdasdasd',
            //   userName: 'kacpero',
            //   passwordRepeat: 'asdasdasd',
            // }}
            onSubmit={submitForm}
            validationSchema={validationForm()}
          >
            {({ handleSubmit, errors }) => (
              <>
                <form onSubmit={handleSubmit}>
                  <Field
                    component={RegularField}
                    errors={errors}
                    icon={SVG_TYPE.USER}
                    name="userName"
                    placeholder="Nazwa użytkownika "
                    type="text"
                    fullWidth
                  />
                  <Field
                    component={RegularField}
                    errors={errors}
                    icon={SVG_TYPE.EMAIL}
                    name="email"
                    placeholder="Adres email"
                    type="text"
                    fullWidth
                  />
                  <Field
                    component={RegularField}
                    errors={errors}
                    icon={SVG_TYPE.LOCK}
                    name="password"
                    placeholder="Hasło"
                    type="password"
                    fullWidth
                  />
                  <Field
                    component={RegularField}
                    errors={errors}
                    icon={SVG_TYPE.LOCK}
                    name="passwordRepeat"
                    placeholder="Powtórz hasło"
                    type="password"
                    fullWidth
                  />

                  <div className="isLoading">
                    <Button
                      className="btn--default"
                      label="Zarejestruj się"
                      type="default"
                      position="center-full"
                    />
                    {isLoading && <SVG type={SVG_TYPE.SPINNER_LOADING} />}
                  </div>
                  <div className="modal__paragraph--wrapper">
                    <p className="modal__paragraph--ligth">masz już konto? </p>
                    <div
                      className="modal__paragraph--dark modal__paragraph--cursor"
                      onClick={() => changeModalType(LOGIN)}
                    >
                      Zaloguj się
                    </div>
                  </div>
                </form>
              </>
            )}
          </Formik>
        </>
      ) : (
        <div>
          <ModalTitle position="center" title="Sprawdź skrzynkę mailową!" />
          <div className="modal--check-mail">
            <SVG type={SVG_TYPE.CHECK_MAIL} />
          </div>
          <p className="modal--h3">
            Wysłaliśmy link do aktywacji konta na adres:
          </p>
          <p className="modal--email"> {isMailSent} </p>
        </div>
      )}
    </>
  );
};

export default RegistrationForm;

RegistrationForm.propTypes = {
  changeModalType: func.isRequired,
  registerUser: func.isRequired,
};
