import { getInstagramPostIdea as getInstagramPostIdeaConnector } from "shared/connectors/instagramConnector";
import { INSTAGRAM } from "store/types";

const init = () => ({
  type: INSTAGRAM.GET_INSTAGRAM_POST_IDEA_INIT,
});

const success = (data) => ({
  type: INSTAGRAM.GET_INSTAGRAM_POST_IDEA_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: INSTAGRAM.GET_INSTAGRAM_POST_IDEA_FAILURE,
});

const getInstagramPostIdea = (userId, status) => async (dispatch) => {
  dispatch(init());
  try {
    const response = await getInstagramPostIdeaConnector(userId, status);
    dispatch(success(response.data.posts));
    return response.data;
  } catch (err) {
    dispatch(failure());
    return err.response ? err.response.data : err.message;
  }
};

export default getInstagramPostIdea;
