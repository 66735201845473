import { createSelector } from "reselect";
// import { RootState } from 'Store/Reducers/RootReducer';

const getInstagramStore = (state) => state.instagramStore;
const getAllInstagramAccounts = createSelector(
  getInstagramStore,
  (state) => state.allAccounts
);
const getInstagramDetails = createSelector(
  getInstagramStore,
  (state) => state.instagramDetails
);
const getInstagramPostIdea = createSelector(
  getInstagramStore,
  (state) => state.instagramPostIdea
);
const getInstagramTags = createSelector(
  getInstagramStore,
  (state) => state.instagramTags
);
const getInstagramPostTime = createSelector(
  getInstagramStore,
  (state) => state.instagramPostTime
);

export {
  getAllInstagramAccounts,
  getInstagramDetails,
  getInstagramPostIdea,
  getInstagramTags,
  getInstagramPostTime,
};
