import React from "react";
import { VALIDATION_MESSAGES } from "shared/consts";
import { Modal, ModalTitle, Button, RegularField } from "shared/components";
import { Formik, Field } from "formik";
import { string, object } from "yup";
import { func } from "prop-types";

const AddProfile = ({
  manageModal,
  setManageModal,
  addInstagramAccountAction,
}) => {
  const closeModal = () => setManageModal({ isModalOpen: false });
  const handleOnSubmit = async ({ token }) => {
    await addInstagramAccountAction(token);
  };

  const validationSchema = () =>
    object().shape({
      token: string().required(VALIDATION_MESSAGES.REQUIRED).nullable(),
    });

  const initialValues = {
    token: "",
  };

  return (
    <>
      <Modal
        className="add-instagram-account"
        opened={manageModal.isModalOpen === "adding"}
        onClose={() => closeModal()}
      >
        <div className="user-type-modal">
          <ul className="user-type-modal--left">
            <Formik
              onSubmit={handleOnSubmit}
              validationSchema={validationSchema()}
              initialValues={initialValues}
              enableReinitialize
            >
              {({ handleSubmit, errors }) => (
                <>
                  <ModalTitle title={`Dodawanie profilu INSTAGRAM`} />
                  <>
                    <Field
                      component={RegularField}
                      errors={errors}
                      label="Token instagrama"
                      name="token"
                      type="text"
                      customBorder="customize-field"
                    />
                    <div className="user-type-modal__buttons">
                      <Button
                        label="Anuluj"
                        className="btn--frame-dark"
                        onClick={() => closeModal()}
                      />
                      <Button
                        label="dodaj profil"
                        className="btn--normal"
                        onClick={() => handleSubmit()}
                      />
                    </div>
                  </>
                </>
              )}
            </Formik>
          </ul>
        </div>
      </Modal>
    </>
  );
};

export default AddProfile;

AddProfile.propTypes = {
  addInstagramAccountAction: func.isRequired,
  setManageModal: func.isRequired,
  setAccountType: func.isRequired,
};
