import { updateInstagramTag as updateInstagramHashtagsConnector } from "shared/connectors/instagramConnector";
import { INSTAGRAM } from "store/types";
import { NOTIFY_TYPE, NOTIFY_LABEL } from "shared/consts";
import { Notify } from "shared/components";
import { toast } from "react-toastify";

const init = () => ({
  type: INSTAGRAM.UPDATE_INSTAGRAM_HASHTAGS_INIT,
});

const success = (data, form) => ({
  type: INSTAGRAM.UPDATE_INSTAGRAM_HASHTAGS_SUCCESS,
  payload: {
    data,
    form,
  },
});

const failure = () => ({
  type: INSTAGRAM.UPDATE_INSTAGRAM_HASHTAGS_FAILURE,
});

const updateInstagramHashtags = (hashtags, userId) => async (dispatch) => {
  dispatch(init());
  try {
    const response = await updateInstagramHashtagsConnector(hashtags, userId);
    dispatch(success(response.data, hashtags));
    toast(
      <Notify
        label={NOTIFY_LABEL.INSTAGRAM_HASHTAGS_UPDATED}
        type={NOTIFY_TYPE.CHECKED}
        color="green"
      />
    );
    return response.data;
  } catch (err) {
    dispatch(failure());
    toast(
      <Notify
        label={NOTIFY_LABEL.INSTAGRAM_HASHTAGS_UPDATE_WARNING}
        type={NOTIFY_TYPE.WARNING}
        color="red"
      />
    );
    return err.response.data;
  }
};

export default updateInstagramHashtags;
