const INSTAGRAM = {
  GET_INSTAGRAM_ACCOUNTS_INIT: "GET_INSTAGRAM_ACCOUNTS_INIT",
  GET_INSTAGRAM_ACCOUNTS_SUCCESS: "GET_INSTAGRAM_ACCOUNTS_SUCCESS",
  GET_INSTAGRAM_ACCOUNTS_FAILURE: "GET_INSTAGRAM_ACCOUNTS_FAILURE",

  UPDATE_INSTAGRAM_BIO_INIT: "UPDATE_INSTAGRAM_BIO_INIT",
  UPDATE_INSTAGRAM_BIO_SUCCESS: "UPDATE_INSTAGRAM_BIO_SUCCESS",
  UPDATE_INSTAGRAM_BIO_FAILURE: "UPDATE_INSTAGRAM_BIO_FAILURE",

  GET_INSTAGRAM_DETAILS_INIT: "GET_INSTAGRAM_DETAILS_INIT",
  GET_INSTAGRAM_DETAILS_SUCCESS: "GET_INSTAGRAM_DETAILS_SUCCESS",
  GET_INSTAGRAM_DETAILS_FAILURE: "GET_INSTAGRAM_DETAILS_FAILURE",

  UPDATE_INSTAGRAM_IDEAS_INIT: "UPDATE_INSTAGRAM_IDEAS_INIT",
  UPDATE_INSTAGRAM_IDEAS_SUCCESS: "UPDATE_INSTAGRAM_IDEAS_SUCCESS",
  UPDATE_INSTAGRAM_IDEAS_FAILURE: "UPDATE_INSTAGRAM_IDEAS_FAILURE",

  REFRESH_ACCOUNT_INSTAGRAM_INIT: "REFRESH_ACCOUNT_INSTAGRAM_INIT",
  REFRESH_ACCOUNT_INSTAGRAM_SUCCESS: "REFRESH_ACCOUNT_INSTAGRAM_SUCCESS",
  REFRESH_ACCOUNT_INSTAGRAM_FAILURE: "REFRESH_ACCOUNT_INSTAGRAM_FAILURE",

  UPDATE_INSTAGRAM_IMAGE_PROMPT_INIT: "UPDATE_INSTAGRAM_IMAGE_PROMPT_INIT",
  UPDATE_INSTAGRAM_IMAGE_PROMPT_SUCCESS:
    "UPDATE_INSTAGRAM_IMAGE_PROMPT_SUCCESS",
  UPDATE_INSTAGRAM_IMAGE_PROMPT_FAILURE:
    "UPDATE_INSTAGRAM_IMAGE_PROMPT_FAILURE",

  GET_ALL_INSTAGRAM_ACCOUNTS_INIT: "GET_ALL_INSTAGRAM_ACCOUNTS_INIT",
  GET_ALL_INSTAGRAM_ACCOUNTS_SUCCESS: "GET_ALL_INSTAGRAM_ACCOUNTS_SUCCESS",
  GET_ALL_INSTAGRAM_ACCOUNTS_FAILURE: "GET_ALL_INSTAGRAM_ACCOUNTS_FAILURE",

  GET_INSTAGRAM_ACCOUNT_INFO_INIT: "GET_INSTAGRAM_ACCOUNT_INFO_INIT",
  GET_INSTAGRAM_ACCOUNT_INFO_SUCCESS: "GET_INSTAGRAM_ACCOUNT_INFO_SUCCESS",
  GET_INSTAGRAM_ACCOUNT_INFO_FAILURE: "GET_INSTAGRAM_ACCOUNT_INFO_FAILURE",

  GET_INSTAGRAM_POST_IDEA_INIT: "GET_INSTAGRAM_POST_IDEA_INIT",
  GET_INSTAGRAM_POST_IDEA_SUCCESS: "GET_INSTAGRAM_POST_IDEA_SUCCESS",
  GET_INSTAGRAM_POST_IDEA_FAILURE: "GET_INSTAGRAM_POST_IDEA_FAILURE",

  UPDATE_INSTAGRAM_POST_IDEA_INIT: "UPDATE_INSTAGRAM_POST_IDEA_INIT",
  UPDATE_INSTAGRAM_POST_IDEA_SUCCESS: "UPDATE_INSTAGRAM_POST_IDEA_SUCCESS",
  UPDATE_INSTAGRAM_POST_IDEA_FAILURE: "UPDATE_INSTAGRAM_POST_IDEA_FAILURE",

  UPDATE_INSTAGRAM_HASHTAGS_INIT: "UPDATE_INSTAGRAM_HASHTAGS_INIT",
  UPDATE_INSTAGRAM_HASHTAGS_SUCCESS: "UPDATE_INSTAGRAM_HASHTAGS_SUCCESS",
  UPDATE_INSTAGRAM_HASHTAGS_FAILURE: "UPDATE_INSTAGRAM_HASHTAGS_FAILURE",

  GET_INSTAGRAM_TAGS_INIT: "GET_INSTAGRAM_TAGS_INIT",
  GET_INSTAGRAM_TAGS_SUCCESS: "GET_INSTAGRAM_TAGS_SUCCESS",
  GET_INSTAGRAM_TAGS_FAILURE: "GET_INSTAGRAM_TAGS_FAILURE",

  DELETE_INSTAGRAM_POST_IDEA_INIT: "DELETE_INSTAGRAM_POST_IDEA_INIT",
  DELETE_INSTAGRAM_POST_IDEA_SUCCESS: "DELETE_INSTAGRAM_POST_IDEA_SUCCESS",
  DELETE_INSTAGRAM_POST_IDEA_FAILURE: "DELETE_INSTAGRAM_POST_IDEA_FAILURE",

  DELETE_INSTAGRAM_TAG_INIT: "DELETE_INSTAGRAM_TAG_INIT",
  DELETE_INSTAGRAM_TAG_SUCCESS: "DELETE_INSTAGRAM_TAG_SUCCESS",
  DELETE_INSTAGRAM_TAG_FAILURE: "DELETE_INSTAGRAM_TAG_FAILURE",

  UPDATE_INSTAGRAM_POST_CAPTION_INIT: "UPDATE_INSTAGRAM_POST_CAPTION_INIT",
  UPDATE_INSTAGRAM_POST_CAPTION_SUCCESS:
    "UPDATE_INSTAGRAM_POST_CAPTION_SUCCESS",
  UPDATE_INSTAGRAM_POST_CAPTION_FAILURE:
    "UPDATE_INSTAGRAM_POST_CAPTION_FAILURE",

  UPDATE_INSTAGRAM_POST_IMAGE_PROMPT_FOR_AI_INIT:
    "UPDATE_INSTAGRAM_POST_IMAGE_PROMPT_FOR_AI_INIT",
  UPDATE_INSTAGRAM_POST_IMAGE_PROMPT_FOR_AI_SUCCESS:
    "UPDATE_INSTAGRAM_POST_IMAGE_PROMPT_FOR_AI_SUCCESS",
  UPDATE_INSTAGRAM_POST_IMAGE_PROMPT_FOR_AI_FAILURE:
    "UPDATE_INSTAGRAM_POST_IMAGE_PROMPT_FOR_AI_FAILURE",

  UPDATE_INSTAGRAM_POST_STATUS_INIT: "UPDATE_INSTAGRAM_POST_STATUS_INIT",
  UPDATE_INSTAGRAM_POST_STATUS_SUCCESS: "UPDATE_INSTAGRAM_POST_STATUS_SUCCESS",
  UPDATE_INSTAGRAM_POST_STATUS_FAILURE: "UPDATE_INSTAGRAM_POST_STATUS_FAILURE",

  ADD_INSTAGRAM_POST_TIME_INIT: "ADD_INSTAGRAM_POST_TIME_INIT",
  ADD_INSTAGRAM_POST_TIME_SUCCESS: "ADD_INSTAGRAM_POST_TIME_SUCCESS",
  ADD_INSTAGRAM_POST_TIME_FAILURE: "ADD_INSTAGRAM_POST_TIME_FAILURE",

  UPDATE_INSTAGRAM_POST_TIME_INIT: "UPDATE_INSTAGRAM_POST_TIME_INIT",
  UPDATE_INSTAGRAM_POST_TIME_SUCCESS: "UPDATE_INSTAGRAM_POST_TIME_SUCCESS",
  UPDATE_INSTAGRAM_POST_TIME_FAILURE: "UPDATE_INSTAGRAM_POST_TIME_FAILURE",

  DELETE_INSTAGRAM_POST_TIME_INIT: "DELETE_INSTAGRAM_POST_TIME_INIT",
  DELETE_INSTAGRAM_POST_TIME_SUCCESS: "DELETE_INSTAGRAM_POST_TIME_SUCCESS",
  DELETE_INSTAGRAM_POST_TIME_FAILURE: "DELETE_INSTAGRAM_POST_TIME_FAILURE",

  GET_INSTAGRAM_POST_TIMES_INIT: "GET_INSTAGRAM_POST_TIMES_INIT",
  GET_INSTAGRAM_POST_TIMES_SUCCESS: "GET_INSTAGRAM_POST_TIMES_SUCCESS",
  GET_INSTAGRAM_POST_TIMES_FAILURE: "GET_INSTAGRAM_POST_TIMES_FAILURE",

  SCHEDULE_INSTAGRAM_POSTS_INIT: "SCHEDULE_INSTAGRAM_POSTS_INIT",
  SCHEDULE_INSTAGRAM_POSTS_SUCCESS: "SCHEDULE_INSTAGRAM_POSTS_SUCCESS",
  SCHEDULE_INSTAGRAM_POSTS_FAILURE: "SCHEDULE_INSTAGRAM_POSTS_FAILURE",

  SWAP_SCHEDULED_DATES_INIT: "SWAP_SCHEDULED_DATES_INIT",
  SWAP_SCHEDULED_DATES_SUCCESS: "SWAP_SCHEDULED_DATES_SUCCESS",
  SWAP_SCHEDULED_DATES_FAILURE: "SWAP_SCHEDULED_DATES_FAILURE",

  UPDATE_SCHEDULED_POST_DATE_INIT: "UPDATE_SCHEDULED_POST_DATE_INIT",
  UPDATE_SCHEDULED_POST_DATE_SUCCESS: "UPDATE_SCHEDULED_POST_DATE_SUCCESS",
  UPDATE_SCHEDULED_POST_DATE_FAILURE: "UPDATE_SCHEDULED_POST_DATE_FAILURE",
};

export default INSTAGRAM;
