import { getInstagramDetails as getInstagramDetailsConnector } from "shared/connectors/instagramConnector";
import { INSTAGRAM } from "store/types";

const init = () => ({
  type: INSTAGRAM.GET_INSTAGRAM_DETAILS_INIT,
});

const success = (data) => ({
  type: INSTAGRAM.GET_INSTAGRAM_DETAILS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: INSTAGRAM.GET_INSTAGRAM_DETAILS_FAILURE,
});

const getInstagramDetails = (userId) => async (dispatch) => {
  dispatch(init());
  try {
    const response = await getInstagramDetailsConnector(userId);
    dispatch(success(response.data.data));
    return response.data;
  } catch (err) {
    dispatch(failure());
    return err.response ? err.response.data : err.message;
  }
};

export default getInstagramDetails;
