/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from "react";
import { Modal, Button } from "shared/components";
import EmptyImage from "assets/files/empty.jpg";
import { Field, Formik } from "formik";
import { SelectField, DatePickerField } from "shared/components";
import moment from "moment";

const Booking = ({
  isOpen,
  onClose,
  selectedItems,
  instagramPostIdea,
  postId,
  instagramPostTime,
  scheduleInstagramPosts,
}) => {
  const iSMoreThanTwo = selectedItems && selectedItems.length > 2;

  const [periodicy, setPeriodicy] = useState("");
  const [startDate, setStartDate] = useState("");
  const [publicationDates, setPublicationDates] = useState([]);

  const handleOnClose = () => {
    onClose();
    setPeriodicy("");
    setStartDate("");
    setPublicationDates([]);
  };

  const filteredPosts = useMemo(() => {
    if (postId) {
      return instagramPostIdea.filter((post) => post.id === postId);
    } else {
      return instagramPostIdea.filter((post) =>
        selectedItems.includes(post.id)
      );
    }
  }, [selectedItems, instagramPostIdea, postId]);

  const generatePublicationDates = (date, periodicy, postCount) => {
    const startDate = moment(date, "DD/MM/YYYY HH:mm"); // Format wybranej daty z moment.js
    const dates = [];
    let currentDay = startDate.clone();

    for (let i = 0; i < postCount; ) {
      if (periodicy === "twoAday") {
        // Dwa razy dziennie - generujemy dwa posty na ten sam dzień
        const morningTime = generateRandomTimeWithDifferentMinutes(
          instagramPostTime?.postTime?.morning_from,
          instagramPostTime?.postTime?.morning_to
        );
        const afternoonTime = generateRandomTimeWithDifferentMinutes(
          instagramPostTime?.postTime?.afternoon_from,
          instagramPostTime?.postTime?.afternoon_to
        );

        // Dodajemy tylko tyle dat, ile brakuje do osiągnięcia postCount
        if (i < postCount) {
          dates.push(
            currentDay
              .clone()
              .set({ hour: morningTime.hour, minute: morningTime.minute })
              .format("DD/MM/YYYY HH:mm")
          );
          i++; // Zwiększamy licznik tylko, jeśli rzeczywiście dodamy datę
        }
        if (i < postCount) {
          dates.push(
            currentDay
              .clone()
              .set({ hour: afternoonTime.hour, minute: afternoonTime.minute })
              .format("DD/MM/YYYY HH:mm")
          );
          i++; // Zwiększamy licznik tylko, jeśli rzeczywiście dodamy datę
        }

        // Przesuwamy datę do przodu o 1 dzień po dodaniu obu postów na ten sam dzień
        currentDay = currentDay.add(1, "days");
      } else if (periodicy === "oneAday") {
        // Raz dziennie - losowo rano lub popołudniu
        const time =
          Math.random() > 0.5
            ? generateRandomTimeWithDifferentMinutes(
                instagramPostTime?.morning_from,
                instagramPostTime?.morning_to
              )
            : generateRandomTimeWithDifferentMinutes(
                instagramPostTime?.afternoon_from,
                instagramPostTime?.afternoon_to
              );

        dates.push(
          currentDay
            .clone()
            .set({ hour: time.hour, minute: time.minute })
            .format("DD/MM/YYYY HH:mm")
        );
        currentDay = currentDay.add(1, "days");
        i++; // Zwiększamy licznik postów
      } else if (periodicy === "onceEveryThreeDays") {
        // Raz na trzy dni - losowo rano lub popołudniu
        const time =
          Math.random() > 0.5
            ? generateRandomTimeWithDifferentMinutes(
                instagramPostTime?.morning_from,
                instagramPostTime?.morning_to
              )
            : generateRandomTimeWithDifferentMinutes(
                instagramPostTime?.afternoon_from,
                instagramPostTime?.afternoon_to
              );

        dates.push(
          currentDay
            .clone()
            .set({ hour: time.hour, minute: time.minute })
            .format("DD/MM/YYYY HH:mm")
        );
        currentDay = currentDay.add(3, "days");
        i++; // Zwiększamy licznik postów
      }
    }

    return dates;
  };

  const generateRandomTimeWithDifferentMinutes = (from, to) => {
    const [fromHour, fromMinute] = from.split(":").map(Number);
    const [toHour, toMinute] = to.split(":").map(Number);

    // Losowa godzina musi mieścić się w zakresie od 'fromHour' do 'toHour'
    const randomHour =
      fromHour + Math.floor(Math.random() * (toHour - fromHour + 1));

    // Generowanie losowych minut z uwzględnieniem, że minuty mogą wynosić 1-59 (unikamy pełnej godziny 00)
    let randomMinute = generateUniqueMinute(
      fromHour === randomHour ? fromMinute : 1, // Jeśli to początkowa godzina, to minuty od 'fromMinute', nie mniej niż 1
      toHour === randomHour ? toMinute : 59 // Jeśli to końcowa godzina, to minuty do 'toMinute'
    );

    // Upewnij się, że minuta nigdy nie jest pełną godziną (00)
    if (randomMinute === 0) {
      randomMinute = 1; // Zamiast 00 ustaw minutę na 1
    }

    return { hour: randomHour, minute: randomMinute };
  };

  const generateUniqueMinute = (fromMinute, toMinute) => {
    if (fromMinute === toMinute) {
      return fromMinute !== 0 ? fromMinute : 1; // Jeśli minuty są równe i wynoszą 00, zwróć 1
    }
    let minute =
      fromMinute + Math.floor(Math.random() * (toMinute - fromMinute + 1));

    // Upewnij się, że wygenerowana minuta nie wynosi 00 (pełna godzina)
    if (minute === 0) {
      minute = 1; // Zamiast 00 ustaw minutę na 1
    }
    return minute;
  };

  useEffect(() => {
    if (periodicy && startDate) {
      const newDates = generatePublicationDates(
        startDate,
        periodicy,
        filteredPosts.length
      );
      setPublicationDates(newDates);
    }
  }, [periodicy, startDate, filteredPosts.length]);

  const handleOnSubmit = async (values, actions) => {
    const formattedPosts = filteredPosts.map((post, index) => ({
      userId: post.user_id,
      caption: `${post.caption} ${post.tags}`,
      imageUrl: post.image_url,
      scheduledAt: values.publicationDates[index],
      postId: post.id,
    }));

    await scheduleInstagramPosts(formattedPosts);
    handleOnClose();
    actions.resetForm();
  };

  return (
    <>
      <Modal
        className={`bookingHarmonogram ${
          iSMoreThanTwo ? "" : "bookingHarmonogramDate"
        }`}
        opened={isOpen}
        onClose={() => handleOnClose()}
      >
        <div className="user-type-modal">
          <div className="bookingHarmonogram__content">
            <div className="modal--h3">
              Harmonogram dodawania postu na instagramie.
            </div>
            <div className="bookingHarmonogram--date">
              data ostatniej publikacji postu{" "}
              {moment(instagramPostTime?.latestScheduledPostDate).format(
                "DD/MM/YYYY HH:MM"
              )}
            </div>
            <Formik
              initialValues={{
                publicationDates:
                  publicationDates.length > 0
                    ? publicationDates
                    : filteredPosts.map(() => ""),
                periodicy: periodicy,
                date: startDate,
              }}
              enableReinitialize
              onSubmit={(values, actions) => handleOnSubmit(values, actions)}
            >
              {({ handleSubmit, setFieldValue, values }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    {iSMoreThanTwo && (
                      <>
                        <Field
                          name="periodicy"
                          component={SelectField}
                          placeholder="Wybierz częstotliwość dodawania postów"
                          label="Wybierz częstotliwość dodawania postów"
                          options={[
                            { value: "twoAday", label: "Dwa razy dziennie" },
                            { value: "oneAday", label: "Raz dziennie" },
                            {
                              value: "onceEveryThreeDays",
                              label: "Raz na trzy dni",
                            },
                          ]}
                          onChange={(option) => {
                            setFieldValue("periodicy", option.value);
                            setPeriodicy(option.value);
                          }}
                        />
                        <Field
                          name="date"
                          component={DatePickerField}
                          placeholder={"Wybierz datę"}
                          className="add-event-form--date"
                          minDate={new Date()}
                          handleOnChange={(date) => {
                            setFieldValue("date", date);
                            setStartDate(date);
                          }}
                        />
                      </>
                    )}

                    <div className="bookingHarmonogram__list">
                      {filteredPosts.length > 0 &&
                        filteredPosts.map((item, index) => (
                          <div
                            className="bookingHarmonogram__item"
                            key={item.id}
                          >
                            <div className="bookingHarmonogram__item--image">
                              <img src={item.image_url || EmptyImage} alt="" />
                            </div>
                            <div className="bookingHarmonogram__item--caption">
                              {item.caption}
                            </div>
                            <div className="bookingHarmonogram__item--booking">
                              <Field
                                component={DatePickerField}
                                name={`publicationDates[${index}]`}
                                placeholder={"Wybierz datę"}
                                className="add-event-form--date"
                                handleOnChange={(date) =>
                                  setFieldValue(
                                    `publicationDates[${index}]`,
                                    date
                                  )
                                }
                                minDate={new Date()}
                                value={values.publicationDates[index]}
                                withHour
                              />
                            </div>
                          </div>
                        ))}
                    </div>
                    <div className="bookingHarmonogram__buttons">
                      <Button
                        className="btn--frame-dark"
                        label="Anuluj"
                        onClick={() => handleOnClose()}
                      />
                      <Button
                        className="btn--default"
                        label="Potwierdź"
                        onClick={handleSubmit}
                      />
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Booking;
