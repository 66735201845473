import { getInstagramTagsByUser as getInstagramTagsByUserConnector } from "shared/connectors/instagramConnector";
import { INSTAGRAM } from "store/types";

const init = () => ({
  type: INSTAGRAM.GET_INSTAGRAM_TAGS_INIT,
});
const success = (data) => ({
  type: INSTAGRAM.GET_INSTAGRAM_TAGS_SUCCESS,
  payload: {
    data,
  },
});
const failure = () => ({
  type: INSTAGRAM.GET_INSTAGRAM_TAGS_FAILURE,
});
const getInstagramTagsByUser = (userId) => async (dispatch) => {
  dispatch(init());
  try {
    const response = await getInstagramTagsByUserConnector(userId);
    dispatch(success(response.data.tags));
    return response.data;
  } catch (err) {
    dispatch(failure());
    return err.response ? err.response.data : err.message;
  }
};

export default getInstagramTagsByUser;
