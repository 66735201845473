import { updateInstagramPostCaption as updateInstagramPostCaptionConnector } from "shared/connectors/instagramConnector";
import { NOTIFY_TYPE, NOTIFY_LABEL } from "shared/consts";
import { Notify } from "shared/components";
import { toast } from "react-toastify";
import { INSTAGRAM } from "store/types";

const init = () => ({
  type: INSTAGRAM.UPDATE_INSTAGRAM_POST_CAPTION_INIT,
});

const success = (data, postId) => ({
  type: INSTAGRAM.UPDATE_INSTAGRAM_POST_CAPTION_SUCCESS,
  payload: {
    data,
    postId,
  },
});

const failure = () => ({
  type: INSTAGRAM.UPDATE_INSTAGRAM_POST_CAPTION_FAILURE,
});

const updateInstagramPostCaption =
  (postId, caption, status) => async (dispatch) => {
    dispatch(init());
    try {
      const response = await updateInstagramPostCaptionConnector(
        postId,
        caption,
        status
      );
      dispatch(success(response.data, postId));
      toast(
        <Notify
          label={NOTIFY_LABEL.INSTAGRAM_POST_CAPTION_UPDATED}
          type={NOTIFY_TYPE.CHECKED}
          color="green"
        />
      );

      return response.data.generatedCaption;
    } catch (err) {
      dispatch(failure());
      toast(
        <Notify
          label={NOTIFY_LABEL.INSTAGRAM_POST_CAPTION_UPDATE_WARNING}
          type={NOTIFY_TYPE.WARNING}
          color="red"
        />
      );

      return err.response.data;
    }
  };

export default updateInstagramPostCaption;
