import { generateInstagramTags as generateInstagramTagsConnector } from "shared/connectors/chatGptConnector";
import { CHAT_GPT } from "store/types";
import { NOTIFY_TYPE, NOTIFY_LABEL } from "shared/consts";
import { Notify } from "shared/components";
import { toast } from "react-toastify";

const init = () => ({
  type: CHAT_GPT.GENERATE_TAGS_INIT,
});

export const success = (tags) => ({
  type: CHAT_GPT.GENERATE_TAGS_SUCCESS,
  payload: {
    tags,
  },
});

const failure = () => ({
  type: CHAT_GPT.GENERATE_TAGS_FAILURE,
});

const generateInstagramTags = (message, user_id) => async (dispatch) => {
  dispatch(init());
  try {
    const response = await generateInstagramTagsConnector(message, user_id);
    dispatch(success(response.data.tags));

    toast(
      <Notify
        label={NOTIFY_LABEL.CHAT_GPT_TAGS_GENERATED}
        type={NOTIFY_TYPE.CHECKED}
        color="green"
      />
    );

    return response.data.tags;
  } catch (err) {
    dispatch(failure());
    toast(
      <Notify
        label={NOTIFY_LABEL.CHAT_GPT_TAGS_GENERATION_ERROR}
        type={NOTIFY_TYPE.WARNING}
        color="red"
      />
    );

    return err.response?.data || "Error";
  }
};

export default generateInstagramTags;
