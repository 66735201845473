import { CHAT_GPT } from "store/types";

const INITIAL_STATE = {
  chatResponse: {},
};

const chatGptReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action;

  switch (action.type) {
    case CHAT_GPT.GET_INSTAGRAM_ACCOUNT_INFO_SUCCESS: {
      return {
        ...state,
        chatResponse: payload.data,
      };
    }
    default:
      return state;
  }
};

export default chatGptReducer;
