import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { SVG } from "shared/components";

const CalendarField = ({
  field: { name, onChange, value },
  form,
  errors,
  placeholder,
  fullWidth,
  className,
  onBlur,
  icon,
  iconPlacement,
  customBorder,
  label,
  showTimeSelect,
}) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    value ? new Date(value) : null
  );
  const [time, setTime] = useState(
    value
      ? `${new Date(value).getHours().toString().padStart(2, "0")}:${new Date(
          value
        )
          .getMinutes()
          .toString()
          .padStart(2, "0")}`
      : "12:00"
  );
  const calendarRef = useRef(null);

  const isError = !!(form.touched[name] && errors[name]);
  const isCustomErrorLabel = customBorder && isError;
  const fieldClasses = `${customBorder}__input ${
    isError ? `${customBorder}__input--error` : ""
  } ${!icon ? `${customBorder}--noIcon` : ""} ${className}`;

  // Zamknięcie popupu po kliknięciu poza nim
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalendar(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const dateTime = new Date(date);
    const [hours, minutes] = time.split(":");
    dateTime.setHours(hours);
    dateTime.setMinutes(minutes);
    onChange({ target: { name, value: dateTime } });
  };

  const handleTimeChange = (e) => {
    setTime(e.target.value);
    if (selectedDate) {
      const dateTime = new Date(selectedDate);
      const [hours, minutes] = e.target.value.split(":");
      dateTime.setHours(hours);
      dateTime.setMinutes(minutes);
      onChange({ target: { name, value: dateTime } });
    }
  };

  return (
    <div className={customBorder} ref={calendarRef}>
      <div
        className={`${customBorder}__container ${
          fullWidth ? `${customBorder}__container--full-width` : ""
        }`}
      >
        <div className={fieldClasses}>
          {icon && iconPlacement && (
            <div
              className={`${customBorder}__icon ${customBorder}__icon--${iconPlacement}`}
            >
              <SVG type={icon} className={`${customBorder}__icon--item`} />
            </div>
          )}
          <input
            name={name}
            placeholder={placeholder}
            value={
              selectedDate ? `${selectedDate.toLocaleDateString()} ${time}` : ""
            }
            onClick={() => setShowCalendar(true)}
            onBlur={onBlur}
            className={`form__field ${
              isError && `${customBorder}__input-error`
            } `}
            readOnly
          />
          {customBorder !== "regular-field" && (
            <div
              className={`form__label ${
                isCustomErrorLabel ? "form__label--error" : ""
              }`}
            >
              {label}
            </div>
          )}
          {isError && (
            <p className={`${customBorder}__error-label`}> {errors[name]}</p>
          )}
        </div>
        {showCalendar && (
          <div className={`${customBorder}__calendar-popup`}>
            <Calendar
              onChange={handleDateChange}
              value={selectedDate}
              className={`${customBorder}__calendar`}
            />
            {showTimeSelect && (
              <div className={`${customBorder}__time-input`}>
                <label>Time:</label>
                <input
                  type="time"
                  value={time}
                  onChange={handleTimeChange}
                  step="300" // Krok co 5 minut
                  className={`${customBorder}__time-input-field`}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

CalendarField.propTypes = {
  className: PropTypes.string,
  customBorder: PropTypes.string,
  errors: PropTypes.object.isRequired,
  field: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.string,
      PropTypes.number,
    ]),
  }).isRequired,
  form: PropTypes.object.isRequired,
  fullWidth: PropTypes.bool,
  icon: PropTypes.string,
  iconPlacement: PropTypes.string,
  label: PropTypes.string,
  onBlur: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  showTimeSelect: PropTypes.bool,
};

CalendarField.defaultProps = {
  className: "",
  customBorder: "regular-field",
  fullWidth: false,
  icon: null,
  iconPlacement: "left",
  label: "",
  placeholder: "",
  showTimeSelect: true,
};

export default CalendarField;
