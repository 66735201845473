import API from "shared/connectors/config";

export function getAccountInfo(user) {
  return API.get(`/instagram/profile?user=${user}`);
}

// Pobieranie wszystkich kont Instagram
export function getAllInstagramAccounts() {
  return API.get("/instagram/all-accounts");
}

// Odświeżanie pojedynczego konta Instagram
export function refreshInstagramAccount(userId) {
  return API.post(`/instagram/update-account`, { user_id: userId });
}

// Dodawanie nowego konta Instagram
export function addInstagramAccount(token) {
  return API.post("/instagram/add-account", { token });
}

// Aktualizowanie opisu (bio) na koncie Instagram
export function updateInstagramBio(userId, biography) {
  return API.post("/instagram/update-bio", { user_id: userId, biography });
}

// Aktualizowanie idei dla konta Instagram
export function updateInstagramIdeas(userId, ideas) {
  return API.post("/instagram/update-ideas", { user_id: userId, ideas });
}

// Aktualizowanie image prompt dla konta Instagram
export function updateInstagramImagePrompt(userId, image) {
  return API.post("/instagram/update-image-prompt-idea", {
    user_id: userId,
    image,
  });
}

// Aktualizowanie idei dla konta Instagram
export function updateInstagramTag(userId, hashtags) {
  return API.post("/instagram/update-tags", { user_id: userId, hashtags });
}

// funkcja do pobierania szczegółowych danych konta Instagram
export function getInstagramDetails(userId) {
  return API.get(`/instagram/details?user_id=${userId}`);
}

export function getInstagramPostIdea(userId, status) {
  return API.post("/instagram/posts-idea", { user_id: userId, status });
}

export function updateInstagramPostIdea(postId, data) {
  return API.post(`/instagram/post/${postId}`, data);
}

export function updateInstagramPostStatus(postId, data) {
  return API.post(`/instagram/post/status/${postId}`, data);
}

export function deleteInstagramPostIdea(postId) {
  return API.delete(`/instagram/post/${postId}`);
}

export function getInstagramTagsByUser(userId) {
  return API.get(`/instagram/tags/${userId}`);
}

export function deleteInstagramTag(tagId) {
  return API.delete(`/instagram/tags/${tagId}`);
}

export function updateInstagramPostCaption(postId, caption, status) {
  return API.post(`/instagram/post-caption/${postId}`, { caption, status });
}

export function updateInstagramPostImagePrompt(postId, imagePrompt, status) {
  return API.post(`/instagram/update-image-ai/${postId}`, {
    imagePrompt,
    status,
  });
}

// Pobieranie wszystkich Instagram Post Times dla danego użytkownika
export function getInstagramPostTimes(userId) {
  return API.get(`/post-times?user_id=${userId}`);
}

// Dodawanie nowego Instagram Post Time dla danego konta
export function addInstagramPostTime(postTimeData) {
  return API.post("/post-times", postTimeData);
}

// Aktualizacja istniejącego Instagram Post Time
export function updateInstagramPostTime(postTimeId, updatedData) {
  return API.put(`/post-times/${postTimeId}`, updatedData);
}

// Usuwanie istniejącego Instagram Post Time
export function deleteInstagramPostTime(postTimeId) {
  return API.delete(`/post-times/${postTimeId}`);
}

export function scheduleInstagramPosts(postData) {
  return API.post("/instagram/schedule-post", { posts: postData });
}

export function swapScheduledDates(currentId, previousId) {
  return API.post("/instagram/swap-scheduled-dates", {
    current_id: currentId,
    previous_id: previousId,
  });
}

export function updateScheduledPostDate(postId, newDate) {
  return API.post("/instagram/update-scheduled-post-date", {
    id: postId,
    date: newDate,
  });
}
