const ROUTES = {
  HOME_PAGE: "/",

  USER_LIST: "/instagram",
  CONTACT: "/kontakt",
  BASKET: "/koszyk",
  // USER REGISTER
  INACTIVE_TOKEN: "/link-nieaktywny",
  ACTIVATE_PAGE: "/aktywacja",
  SEND_LINK_NEW_PASSWORD: "/zmiana-hasla",
  INACTIVE_ACCOUNT: "/konto-nieaktywne",
  // END USER
  DASHBOARD: "/dashboard",
  USERS: "/users",
  PRODUCT: "/dashboard/produkt",
  FAVORITES: "/dashboard/ulubione",

  // PROFIL
  PROFILE: "/profil",
  SETTINGS: "/profil/ustawienia",

  // SKLEP
  SHOP: "/sklep",
  DELIVERY_PAGE: "/sklep/metody-dostawy",
  MY_ADVERTISEMENTS: "/sklep/moje-ogloszenia",
  ADD_PRODUCT_PAGE: "/sklep/dodaj-produkt",

  // ARTYKUŁY
  ARTICLE: "/artykul/:article",

  // RZEMIOSŁO
  CRAFTS: "/rzemioslo",
  CATEGORY_ARTICLE: "rzemioslo/:category",
  CATEGORY: "/rzemioslo/zarzadzanie-kategoriami",

  // Wydarzenia
  EVENTS: "/wydarzenia",
  ADD_EVENT: "/dodaj-wydarzenie",
  EVENT_MANAGE: "/moje-wydarzenia",
  SINGLE_EVENT_PAGE: "/wydarzenie",

  // FORUM
  FORUM: "/forum",
  FORUM_ADD_QUESTION: "/forum/zadaj-pytanie",
  FORUM_QUESTION: "/forum/pytanie/:question",
};

export default ROUTES;
