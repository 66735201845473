import { deleteInstagramTag as deleteInstagramTagConnector } from "shared/connectors/instagramConnector";
import { NOTIFY_TYPE, NOTIFY_LABEL } from "shared/consts";
import { Notify } from "shared/components";
import { toast } from "react-toastify";
import { INSTAGRAM } from "store/types";

const init = () => ({
  type: INSTAGRAM.DELETE_INSTAGRAM_TAG_INIT,
});

const success = (tagId) => ({
  type: INSTAGRAM.DELETE_INSTAGRAM_TAG_SUCCESS,
  payload: {
    tagId,
  },
});

const failure = () => ({
  type: INSTAGRAM.DELETE_INSTAGRAM_TAG_FAILURE,
});

const deleteInstagramTag = (tagId) => async (dispatch) => {
  dispatch(init());
  try {
    await deleteInstagramTagConnector(tagId);
    dispatch(success(tagId));
    toast(
      <Notify
        label={NOTIFY_LABEL.INSTAGRAM_TAG_DELETED}
        type={NOTIFY_TYPE.CHECKED}
        color="green"
      />
    );
    return true;
  } catch (err) {
    dispatch(failure());
    toast(
      <Notify
        label={NOTIFY_LABEL.INSTAGRAM_TAG_DELETE_WARNING}
        type={NOTIFY_TYPE.WARNING}
        color="red"
      />
    );
    return err.response ? err.response.data : err.message;
  }
};

export default deleteInstagramTag;
