const SVG_TYPE = {
  HOME: "HOME",
  BOOKMARK: "BOOKMARK",
  SETTINGS: "SETTINGS",
  ADD: "ADD",
  USER: "USER",
  CLOSE: "CLOSE",
  LOCK: "LOCK",
  EMAIL: "EMAIL",
  USER_CIRCLE: "USER_CIRCLE",
  PHONE: "PHONE",
  DROP_DOWN: "DROP_DOWN",
  ONOFF: "ONOFF",
  INSTAGRAM: "INSTAGRAM",
  PINTEREST: "PINTEREST",
  FACEBOOK: "FACEBOOK",
  CHAT: "CHAT",
  ARROW: "ARROW",
  CHEVRONE: "CHEVRONE",
  SEARCH: "SEARCH",
  CRAFTS: "CRAFTS",
  SUN: "SUN",
  MOON: "MOON",
  HEART: "HEART",
  ADD_CART: "ADD_CART",
  USERS: "USERS",
  DOTS: "DOTS",
  MAIL: "MAIL",
  SHOP: "SHOP",
  LOUPE: "LOUPE",
  BASKET: "BASKET",
  ACCOUNT: "ACCOUNT",
  HEART_FULL: "HEART_FULL",
  STAR: "STAR",
  STAR_HALF: "STAR_HALF",
  STAR_FULL: "STAR_FULL",
  FILTER: "FILTER",
  PRODUCT: "PRODUCT",
  CATEGORY: "CATEGORY",
  SUBCATEGORY: "SUBCATEGORY",
  PLUS: "PLUS",
  MINUS: "MINUS",
  EDIT: "EDIT",
  JOIN_US: "JOIN_US",
  WAVE_BOTTOM: "WAVE_BOTTOM",
  WAVE_TOP: "WAVE_TOP",
  FORUM: "FORUM",
  STATISTIC: "STATISTIC",
  HAMBURGER: "HAMBURGER",
  URL: "URL",
  FORUM_2: "FORUM_2",
  LOADING: "LOADING",
  SPINNER_LOADING: "SPINNER_LOADING",
  CHECK: "CHECK",
  BIN: "BIN",
  WARNING: "WARNING",
  SEND_MAIL: "SEND_MAIL",
  CHECK_MAIL: "CHECK_MAIL",
  LOCK_ILU: "LOCK_ILU",
  TIME_MANAGE_ILU: "TIME_MANAGE_ILU",
  JOIN_OK: "JOIN_OK",
  SEND_LINK_PASSWORD_ILU: "SEND_LINK_PASSWORD_ILU",
  SEND_MAIL_CHECK_ILU: "SEND_MAIL_CHECK_ILU",
  INACTIVE_ACCOUNT_ILU: "INACTIVE_ACCOUNT_ILU",
  PRODUCT_PHOTO: "PRODUCT_PHOTO",
  CAMERA: "CAMERA",
  CUSTOMIZE_FORM: "CUSTOMIZE_FORM",
  DELIVERY: "DELIVERY",
  DELIVERY_ADD: "DELIVERY_ADD",
  DELIVERY_DELETE: "DELIVERY_DELETE",
  BEST_PRICE: "BEST_PRICE",
  PRICE_LABEL: "PRICE_LABEL",
  AVATAR: "AVATAR",
  GALLERY: "GALLERY",
  ADDRESS: "ADDRESS",
  PLUS_CIRCLE: "PLUS_CIRCLE",
  LONG_ARROW_RIGHT: "LONG_ARROW_RIGHT",
  LONG_ARROW_LEFT: "LONG_ARROW_LEFT",
  SLIDE_ARROW: "SLIDE_ARROW",
  QUOTES: "QUOTES",
  YOUTUBE: "YOUTUBE",
  LOGOUT: "LOGOUT",
  DASHBOARD: "DASHBOARD",
  MENU: "MENU",
  BOARD: "BOARD",
  CALENDAR: "CALENDAR",
  CALENDAR_ADD: "CALENDAR_ADD",
  EMPTY_CALENDAR: "EMPTY_CALENDAR",
  CALENDAR_LIST: "CALENDAR_LIST",
  FORUM_ICON: "FORUM_ICON",
  ARTICLE: "ARTICLE",
  TIME: "TIME",
  EYE: "EYE",
  COMMENT: "COMMENT",
  ADD_TAGS: "ADD_TAGS",
  TAG: "TAG",
  REFRESH: "REFRESH",
};

export default SVG_TYPE;
