import ROUTES from "./routes";
import USER_ROLE from "./userRole";
import USER_TYPE from "./userType";

const { ADMIN, CUSTOMER } = USER_ROLE;
const { CRAFTSMAN, PARTNER } = USER_TYPE;

const PROTECTED_ROUTES = new Map([
  [ROUTES.CATEGORY, [ADMIN]],
  [ROUTES.DELIVERY_PAGE, [ADMIN]],
  [ROUTES.USERS, [ADMIN]],
  [ROUTES.USER_LIST, [ADMIN]],
  [ROUTES.SETTINGS, [ADMIN]],
  [ROUTES.ADD_PRODUCT_PAGE, [CRAFTSMAN, PARTNER]],
  [ROUTES.MY_ADVERTISEMENTS, [ADMIN, CUSTOMER]],
  [ROUTES.DASHBOARD, [ADMIN, CUSTOMER]],
  [ROUTES.FAVORITES, [CUSTOMER]],
  [ROUTES.PROFILE, [ADMIN, CUSTOMER]],
  [ROUTES.ADD_EVENT, [ADMIN, CUSTOMER, CRAFTSMAN, PARTNER]],
  [ROUTES.EVENT_MANAGE, [ADMIN, CUSTOMER, CRAFTSMAN, PARTNER]],
]);

export default PROTECTED_ROUTES;
