import { addInstagramPostTime as addInstagramPostTimeConnector } from "shared/connectors/instagramConnector";
import { NOTIFY_TYPE, NOTIFY_LABEL } from "shared/consts";
import { Notify } from "shared/components";
import { toast } from "react-toastify";
import { INSTAGRAM } from "store/types";

const init = () => ({
  type: INSTAGRAM.ADD_INSTAGRAM_POST_TIME_INIT,
});

const success = (data) => ({
  type: INSTAGRAM.ADD_INSTAGRAM_POST_TIME_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: INSTAGRAM.ADD_INSTAGRAM_POST_TIME_FAILURE,
});

const addInstagramPostTime = (postTimeData) => async (dispatch) => {
  dispatch(init());
  try {
    const response = await addInstagramPostTimeConnector(postTimeData);
    dispatch(success(response.data));
    toast(
      <Notify
        label={NOTIFY_LABEL.INSTAGRAM_POST_TIME_ADDED}
        type={NOTIFY_TYPE.CHECKED}
        color="green"
      />
    );
    return response.data;
  } catch (err) {
    dispatch(failure());
    toast(
      <Notify
        label={NOTIFY_LABEL.INSTAGRAM_POST_TIME_ADD_WARNING}
        type={NOTIFY_TYPE.WARNING}
        color="red"
      />
    );
    return err.response ? err.response.data : err.message;
  }
};

export default addInstagramPostTime;
