import React from "react";
import { Modal, Button } from "shared/components";
import { bool, func, string } from "prop-types";
import { SVG_TYPE } from "shared/consts";
import { RegularField } from "shared/components";
import { Field, Formik } from "formik";

const HoursSchedule = ({
  isOpen,
  onClose,
  userId,
  updateInstagramPostTime,
  addInstagramPostTime,
  instagramPostTime,
}) => {
  const submitForm = async (values) => {
    await addInstagramPostTime({ ...values });
  };

  const initialValues = {
    user_id: userId,
    morning_from: (instagramPostTime?.postTime?.morning_from || "").substring(
      0,
      5
    ),
    morning_to: (instagramPostTime?.postTime?.morning_to || "").substring(0, 5),
    afternoon_from: (
      instagramPostTime?.postTime?.afternoon_from || ""
    ).substring(0, 5),
    afternoon_to: (instagramPostTime?.postTime?.afternoon_to || "").substring(
      0,
      5
    ),
  };

  return (
    <>
      <Modal
        className="instagram-bio-edit"
        opened={isOpen}
        onClose={() => onClose()}
      >
        <div className="user-type-modal">
          <ul className="user-type-modal--left">
            <div className="modal--h3">Godziny publikowania postów</div>
            <Formik initialValues={initialValues} onSubmit={submitForm}>
              {({ handleSubmit, errors }) => (
                <form onSubmit={handleSubmit} className="hours-schedule__form">
                  <div className="hours-schedule__form-heading">
                    <div className="hours-schedule__form-heading--text">
                      Poranek
                    </div>
                    <div className="hours-schedule__form-fields">
                      <Field
                        component={RegularField}
                        errors={errors}
                        name="morning_from"
                        placeholder="Od godziny"
                        fullWidth
                        icon={SVG_TYPE.TIME}
                      />
                      -
                      <Field
                        component={RegularField}
                        errors={errors}
                        name="morning_to"
                        placeholder="Do godziny"
                        fullWidth
                        icon={SVG_TYPE.TIME}
                      />
                    </div>
                  </div>
                  <div className="hours-schedule__form-heading">
                    <div className="hours-schedule__form-heading--text">
                      Popołudnie
                    </div>
                    <div className="hours-schedule__form-fields">
                      <Field
                        component={RegularField}
                        errors={errors}
                        name="afternoon_from"
                        placeholder="Od godziny"
                        fullWidth
                        icon={SVG_TYPE.TIME}
                      />
                      -
                      <Field
                        component={RegularField}
                        errors={errors}
                        name="afternoon_to"
                        placeholder="Do godziny"
                        fullWidth
                        icon={SVG_TYPE.TIME}
                      />
                    </div>
                  </div>

                  <div className="user-type-modal__buttons">
                    <Button
                      label="Anuluj"
                      className="btn--frame-dark"
                      onClick={() => onClose()}
                    />
                    <Button
                      className="btn--default"
                      label="Potwierdź"
                      onClick={() => handleSubmit()}
                    />
                  </div>
                </form>
              )}
            </Formik>
          </ul>
        </div>
      </Modal>
    </>
  );
};

export default HoursSchedule;

HoursSchedule.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  updateInstagramPostTime: func.isRequired,
  addInstagramPostTime: func.isRequired,
  userId: string.isRequired,
};
