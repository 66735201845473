import { getAllInstagramAccounts as getAllInstagramAccountsConnector } from "shared/connectors/instagramConnector";
import { INSTAGRAM } from "store/types";

const init = () => ({
  type: INSTAGRAM.GET_INSTAGRAM_ACCOUNTS_INIT,
});

const success = (data) => ({
  type: INSTAGRAM.GET_INSTAGRAM_ACCOUNTS_SUCCESS,
  payload: {
    data,
  },
});
const failure = () => ({
  type: INSTAGRAM.GET_INSTAGRAM_ACCOUNTS_FAILURE,
});

const getAllInstagramAccounts = () => async (dispatch) => {
  dispatch(init());
  try {
    const response = await getAllInstagramAccountsConnector();
    dispatch(success(response.data.accounts));
    return response.data;
  } catch (err) {
    dispatch(failure());
    return err.response.data;
  }
};

export default getAllInstagramAccounts;
