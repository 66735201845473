import React, { useState } from "react";
import { object, func, string } from "prop-types";
import { Button } from "shared/components";
import EmptyImage from "assets/files/empty.jpg";
import BookingNow from "../BookingNow";
import Booking from "../Booking";
import HoursSchedule from "../HoursSchedule";

const ReadyToUpload = ({
  instagramPostIdea,
  updateInstagramPostTime,
  addInstagramPostTime,
  instagramPostTime,
  userId,
  scheduleInstagramPosts,
}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [showCheckboxes, setShowCheckboxes] = useState(false);
  const [openModal, setOpenModal] = useState({ name: "", postId: "" });
  const isBookingNow = openModal.name === "booking-now";
  const isBookingAll = openModal.name === "booking-all";
  const isBooking = openModal.name === "booking";
  const isHourSchedule = openModal.name === "hourSchedule";

  const handleSelectAll = () => {
    if (selectedItems.length === instagramPostIdea.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(instagramPostIdea.map((item) => item.id));
    }
  };

  const handleSelectItem = (id) => {
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter((item) => item !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const handleSelectFew = () => {
    setShowCheckboxes(true);
  };

  const handleSelectAllButton = () => {
    setShowCheckboxes(true);
    handleSelectAll();
  };

  const handleOfSelected = () => {
    setShowCheckboxes(false);
    setSelectedItems([]);
  };

  const bookingNow = (postId) => {
    setOpenModal({ name: "booking-now", postId });
  };

  const booking = (id) => {
    setOpenModal({ name: "booking", postId: id });
    setSelectedItems([id]);
  };
  const handleBookingCheckeds = () => {
    setOpenModal({ name: "booking-all", postId: "" });
  };

  const openHoursSchedule = () => {
    setOpenModal({ name: "hourSchedule", postId: "" });
  };

  return (
    <>
      <Booking
        scheduleInstagramPosts={scheduleInstagramPosts}
        instagramPostTime={instagramPostTime}
        postId={openModal.postId}
        instagramPostIdea={instagramPostIdea}
        selectedItems={selectedItems}
        isOpen={isBookingAll || isBooking}
        onClose={() => setOpenModal({ name: "", postId: "" })}
      />
      <BookingNow
        isOpen={isBookingNow}
        onClose={() => setOpenModal({ name: "", postId: "" })}
      />
      <HoursSchedule
        instagramPostTime={instagramPostTime}
        addInstagramPostTime={addInstagramPostTime}
        updateInstagramPostTime={updateInstagramPostTime}
        userId={userId}
        isOpen={isHourSchedule}
        onClose={() => setOpenModal({ name: "", userId })}
      />
      <div className="readyToUpload">
        <div className="readyToUpload__actions">
          <Button
            onClick={openHoursSchedule}
            label="Harmonogram godzin"
            className="btn--frame-dark"
          />
          {selectedItems.length > 0 && (
            <div
              className="readyToUpload__actions-item"
              onClick={handleBookingCheckeds}
            >
              Umów zaznaczone
            </div>
          )}
          {showCheckboxes && (
            <div
              className="readyToUpload__actions-item"
              onClick={handleOfSelected}
            >
              Wyłącz zaznaczanie
            </div>
          )}
          <div
            className="readyToUpload__actions-item"
            onClick={handleSelectAllButton}
          >
            Zaznacz wszystkie
          </div>
          <div
            className="readyToUpload__actions-item"
            onClick={handleSelectFew}
          >
            Wybierz kilka
          </div>
        </div>
        <div className="readyToUpload__list">
          {instagramPostIdea.map((item) => {
            return (
              <div key={item.id} className="readyToUpload__item">
                {showCheckboxes && (
                  <div className="readyToUpload__checkbox">
                    <input
                      type="checkbox"
                      checked={selectedItems.includes(item.id)}
                      onChange={() => handleSelectItem(item.id)}
                    />
                  </div>
                )}
                <div className="readyToUpload__image">
                  <img
                    src={item.image_url || EmptyImage}
                    alt={`${item.username}__image`}
                  />
                </div>
                <div className="readyToUpload__caption">
                  <p className="readyToUpload__caption-heading">
                    {item.caption}
                  </p>
                  {item &&
                    item.tags &&
                    item?.tags.length > 0 &&
                    item.tags.map((tag, tagIndex) => {
                      return <span key={tagIndex}>{tag}</span>;
                    })}
                  <div className="readyToUpload__image-buttons hover">
                    <Button
                      onClick={() => bookingNow(item.id)}
                      label="Opublikuj teraz"
                      className="btn--frame-dark"
                    />
                    <Button
                      onClick={() => booking(item.id)}
                      label="Umów"
                      className="btn--default"
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ReadyToUpload;

ReadyToUpload.propTypes = {
  instagramPostTime: object.isRequired,
  instagramPostIdea: object.isRequired,
  updateInstagramPostTime: func.isRequired,
  addInstagramPostTime: func.isRequired,
  scheduleInstagramPosts: func.isRequired,
  userId: string.isRequired,
};
