import { INSTAGRAM } from "store/types";

const INITIAL_STATE = {
  instagramAccount: [],
  allAccounts: [],
  instagramPostIdea: [],
  instagramDetails: {},
  instagramTags: [],
  instagramPostTime: {},
};

const instagramReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action;

  switch (action.type) {
    case INSTAGRAM.SCHEDULE_INSTAGRAM_POSTS_SUCCESS: {
      const scheduledPosts = action.payload.data.data;
      const updatedInstagramPosts = state.instagramPostIdea.filter(
        (post) =>
          !scheduledPosts.some((scheduled) => scheduled.post_id === post.id)
      );
      return {
        ...state,
        instagramPostIdea: updatedInstagramPosts,
      };
    }

    case INSTAGRAM.SWAP_SCHEDULED_DATES_SUCCESS: {
      const { current_id, previous_id } = action.payload.data;

      const currentIndex = state.instagramPostIdea.findIndex(
        (post) => post.id === current_id
      );
      const previousIndex = state.instagramPostIdea.findIndex(
        (post) => post.id === previous_id
      );

      if (currentIndex === -1 || previousIndex === -1) {
        return state;
      }
      const updatedInstagramPosts = [...state.instagramPostIdea];

      const currentPost = updatedInstagramPosts[currentIndex];
      const previousPost = updatedInstagramPosts[previousIndex];

      const tempScheduledAt = currentPost.scheduled_at;

      updatedInstagramPosts[currentIndex] = {
        ...currentPost,
        scheduled_at: previousPost.scheduled_at,
      };
      updatedInstagramPosts[previousIndex] = {
        ...previousPost,
        scheduled_at: tempScheduledAt,
      };

      const temp = updatedInstagramPosts[currentIndex];
      updatedInstagramPosts[currentIndex] =
        updatedInstagramPosts[previousIndex];
      updatedInstagramPosts[previousIndex] = temp;
      updatedInstagramPosts.sort(
        (a, b) => new Date(a.scheduled_at) - new Date(b.scheduled_at)
      );

      return {
        ...state,
        instagramPostIdea: updatedInstagramPosts,
      };
    }

    case INSTAGRAM.GET_INSTAGRAM_ACCOUNT_INFO_SUCCESS: {
      return {
        ...state,
        instagramAccount: payload.data,
      };
    }
    case INSTAGRAM.GET_INSTAGRAM_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        allAccounts: payload.data,
      };
    }
    case INSTAGRAM.ADD_INSTAGRAM_POST_TIME_SUCCESS: {
      return {
        ...state,
        instagramPostTime: payload.data,
      };
    }
    case INSTAGRAM.GET_INSTAGRAM_POST_TIMES_SUCCESS: {
      return {
        ...state,
        instagramPostTime: payload.data,
      };
    }
    case INSTAGRAM.UPDATE_INSTAGRAM_POST_TIME_SUCCESS:
      return {
        ...state,
        instagramPostTime: payload.data,
      };
    case INSTAGRAM.REFRESH_ACCOUNT_INSTAGRAM_SUCCESS: {
      const userId = payload.data.account.user_id;
      return {
        ...state,
        allAccounts: state.allAccounts.map((account) =>
          account.user_id === userId
            ? { ...account, ...payload.data.account }
            : account
        ),
      };
    }
    case INSTAGRAM.GET_INSTAGRAM_DETAILS_INIT: {
      return {
        ...state,
        instagramDetails: {},
      };
    }
    case INSTAGRAM.GET_INSTAGRAM_DETAILS_SUCCESS: {
      return {
        ...state,
        instagramDetails: payload.data,
      };
    }
    case INSTAGRAM.GET_INSTAGRAM_POST_IDEA_SUCCESS: {
      return {
        ...state,
        instagramPostIdea: payload.data,
      };
    }

    case INSTAGRAM.UPDATE_INSTAGRAM_POST_IMAGE_PROMPT_FOR_AI_SUCCESS: {
      return {
        ...state,
        instagramPostIdea: state.instagramPostIdea.map((post) =>
          post.id === action.payload.postId
            ? { ...post, image_prompt: action.payload.data.post.image_prompt }
            : post
        ),
      };
    }

    case INSTAGRAM.UPDATE_INSTAGRAM_POST_CAPTION_SUCCESS: {
      return {
        ...state,
        instagramPostIdea: state.instagramPostIdea.map((post) =>
          post.id === action.payload.postId
            ? { ...post, caption: action.payload.data.updatedCaption }
            : post
        ),
      };
    }
    case INSTAGRAM.UPDATE_INSTAGRAM_POST_IDEA_SUCCESS: {
      const updatedPost = action.payload.data.post;
      const form = action.payload.form;

      if (form.remove === true) {
        return {
          ...state,
          instagramPostIdea: state.instagramPostIdea.filter(
            (post) => post.id !== updatedPost.id
          ),
        };
      }
      return {
        ...state,
        instagramPostIdea: state.instagramPostIdea.map((post) =>
          post.id === updatedPost.id ? { ...post, ...updatedPost } : post
        ),
      };
    }

    case INSTAGRAM.DELETE_INSTAGRAM_POST_IDEA_SUCCESS: {
      const postId = action.payload.postId;
      return {
        ...state,
        instagramPostIdea: state.instagramPostIdea.filter(
          (post) => post.id !== postId
        ),
      };
    }

    case INSTAGRAM.GET_INSTAGRAM_TAGS_INIT: {
      return {
        ...state,
        instagramTags: [],
      };
    }
    case INSTAGRAM.DELETE_INSTAGRAM_TAG_SUCCESS: {
      const tagId = action.payload.tagId;
      return {
        ...state,
        instagramTags: state.instagramTags.filter((tag) => tag.id !== tagId),
      };
    }
    case INSTAGRAM.GET_INSTAGRAM_TAGS_SUCCESS: {
      return {
        ...state,
        instagramTags: action.payload.data,
      };
    }
    default:
      return state;
  }
};

export default instagramReducer;
