import { connect } from "react-redux";
import * as ViewManagementActions from "store/actions/viewManagementActions";
import {
  addInstagramAccountAction,
  getAllInstagramAccounts,
  refreshInstagramAccountAction,
  updateInstagramBio,
  getInstagramDetails,
  updateInstagramIdeas,
  getInstagramPostIdea,
  updateInstagramPostIdea,
  deleteInstagramPostIdea,
  updateInstagramHashtags,
  getInstagramTagsByUser,
  deleteInstagramTag,
  updateInstagramPostCaption,
  updateInstagramImagePrompt,
  updateInstagramPostImagePromptForAi,
  updateInstagramPostStatus,
  getInstagramPostTimes,
  deleteInstagramPostTime,
  updateInstagramPostTime,
  addInstagramPostTime,
  scheduleInstagramPosts,
  swapScheduledDates,
  updateScheduledPostDate,
} from "store/actions/instagram";
import { getIsModalOpened } from "store/selectors/viewManagementSelector";
import { isMobile as checkIsMobile } from "shared/utils";
import { bindActionCreators } from "redux";
import { getAuthorizedUser as getAuthorizedUserSelector } from "store/selectors/userSelector";
import ProfilePage from "./ProfilePage.main";
import * as UserActions from "store/actions/user";
import { getAllCategories } from "store/actions/category";
import {
  generateChatGptResponse,
  generateInstagramTags,
} from "store/actions/chatGpt";
import { getAllCategories as getAllCategoriesSelector } from "store/selectors/categorySelector";
import { getChatGptResponse as getChatGptResponseSelector } from "store/selectors/chatGptSelector";
import {
  getAllInstagramAccounts as getAllInstagramAccountsSelector,
  getInstagramDetails as getInstagramDetailsSelector,
  getInstagramPostIdea as getInstagramPostIdeaSelector,
  getInstagramTags as getInstagramTagsSelector,
  getInstagramPostTime as getInstagramPostTimeSelector,
} from "store/selectors/instagramSelector";

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setAccountType: UserActions.setAccountType,
      getAuthorizedUser: UserActions.getAuthorizedUser,
      closeModal: ViewManagementActions.closeModal,
      openModal: ViewManagementActions.openModal,
      getAllCategories: () => getAllCategories(),
      updateInstagramPostCaption: (postId, caption, status) =>
        updateInstagramPostCaption(postId, caption, status),
      updateInstagramPostImagePromptForAi: (postId, caption, status) =>
        updateInstagramPostImagePromptForAi(postId, caption, status),
      addInstagramAccountAction: (token) => addInstagramAccountAction(token),
      refreshInstagramAccountAction: (userId) =>
        refreshInstagramAccountAction(userId),
      updateInstagramBio: (userId, bio) => updateInstagramBio(userId, bio),
      getAllInstagramAccounts: () => getAllInstagramAccounts(),
      getInstagramDetails: (userId) => getInstagramDetails(userId),
      updateInstagramIdeas: (userId, ideas) =>
        updateInstagramIdeas(userId, ideas),
      updateInstagramImagePrompt: (userId, image) =>
        updateInstagramImagePrompt(userId, image),
      generateChatGptResponse: (message, userId) =>
        generateChatGptResponse(message, userId),
      getInstagramPostIdea: (userId, status) =>
        getInstagramPostIdea(userId, status),
      swapScheduledDates: (currentId, previousId) =>
        swapScheduledDates(currentId, previousId),
      updateInstagramPostStatus: (userId, status) =>
        updateInstagramPostStatus(userId, status),
      updateInstagramPostIdea: (postId, data) =>
        updateInstagramPostIdea(postId, data),
      updateInstagramHashtags: (userId, hashtags) =>
        updateInstagramHashtags(userId, hashtags),
      deleteInstagramPostIdea: (postId) => deleteInstagramPostIdea(postId),
      generateInstagramTags: (message, user_id) =>
        generateInstagramTags(message, user_id),
      getInstagramTagsByUser: (user_id) => getInstagramTagsByUser(user_id),
      deleteInstagramTag: (tagId) => deleteInstagramTag(tagId),
      getInstagramPostTimes: (userId) => getInstagramPostTimes(userId),
      deleteInstagramPostTime: (postTimeId) =>
        deleteInstagramPostTime(postTimeId),
      updateInstagramPostTime: (postTimeId, updatedData) =>
        updateInstagramPostTime(postTimeId, updatedData),
      addInstagramPostTime: (postTimeData) =>
        addInstagramPostTime(postTimeData),
      scheduleInstagramPosts: (postData) => scheduleInstagramPosts(postData),
      updateScheduledPostDate: (postId, newDate) =>
        updateScheduledPostDate(postId, newDate),
    },
    dispatch
  ),
});

function mapStateToProps(state) {
  return {
    categories: getAllCategoriesSelector(state),
    authorizedUser: getAuthorizedUserSelector(state),
    isModalOpened: getIsModalOpened(state),
    isMobile: checkIsMobile(),
    allAccounts: getAllInstagramAccountsSelector(state),
    instagramDetails: getInstagramDetailsSelector(state),
    chatGptResponse: getChatGptResponseSelector(state),
    instagramPostIdea: getInstagramPostIdeaSelector(state),
    instagramTags: getInstagramTagsSelector(state),
    instagramPostTime: getInstagramPostTimeSelector(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
