import { generateChatGptResponse as generateChatGptResponseConnector } from "shared/connectors/chatGptConnector";
import { CHAT_GPT } from "store/types";
import { NOTIFY_TYPE, NOTIFY_LABEL } from "shared/consts";
import { Notify } from "shared/components";
import { toast } from "react-toastify";

const init = () => ({
  type: CHAT_GPT.GENERATE_RESPONSE_INIT,
});

export const success = (data) => ({
  type: CHAT_GPT.GENERATE_RESPONSE_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: CHAT_GPT.GENERATE_RESPONSE_FAILURE,
});

const generateChatGptResponse = (message, user_id) => async (dispatch) => {
  dispatch(init());
  console.log(message);
  try {
    const response = await generateChatGptResponseConnector(message, user_id);
    dispatch(success(response.data));
    toast(
      <Notify
        label={NOTIFY_LABEL.CHAT_GPT_RESPONSE_RECEIVED}
        type={NOTIFY_TYPE.CHECKED}
        color="green"
      />
    );
    return response.data;
  } catch (err) {
    dispatch(failure());
    toast(
      <Notify
        label={NOTIFY_LABEL.CHAT_GPT_RESPONSE_ERROR}
        type={NOTIFY_TYPE.WARNING}
        color="red"
      />
    );
    return err.response?.data || "Error";
  }
};

export default generateChatGptResponse;
