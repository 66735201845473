const NOTIFY_TYPE = {
  DELIVERY_ADD: "Dodano metodę dostawy",
  DELIVERY_DELETE: "Usunięto metodę dostawy",
  DELIVERY_EDIT: "Pomyślna edycja metody dostawy",
  SOMETHING_WRONG: "Ups, coś poszło nie tak",
  ADD_NEW_ADDRESS: "Dodano nowy adres",
  PRODUCT_ADD: "Dodano nowy produkt",
  INSTAGRAM_ACCOUNT_ADDED: "Pomyślnie dodano i zaktualizowano dane o koncie",
  INSTAGRAM_ACCOUNT_ADD_WARNING: "Problem z dodaniem konta",
  INSTAGRAM_ACCOUNT_UPDATED: "Zaktualizowano dane konta",
  INSTAGRAM_ACCOUNT_UPDATE_WARNING: "Błąd aktualizacji danych",
  INSTAGRAM_BIO_UPDATED: "Zaktualizowano opis konta",
  INSTAGRAM_BIO_UPDATE_WARNING: "Błąd aktualizacji opisu",
  INSTAGRAM_IDEAS_UPDATED: "Zaktualizowano idee konta",
  INSTAGRAM_IDEAS_UPDATE_WARNING: "Błąd aktualizacji idei",
  CHAT_GPT_RESPONSE_RECEIVED: "Odpowiedź od ChatGPT otrzymana",
  CHAT_GPT_RESPONSE_ERROR: "Odpowiedź od ChatGPT nieprawidłowa",
  INSTAGRAM_POST_IDEA_UPDATED: "Zaktualizowano pomysł na post",
  INSTAGRAM_POST_IDEA_UPDATE_WARNING: "Błąd aktualizacji pomysłu na post",
  INSTAGRAM_POST_IDEA_DELETED: "Usunięto pomysł na post",
  INSTAGRAM_POST_IDEA_DELETE_WARNING: "Błąd usuwania pomysłu na post",
  CHAT_GPT_TAGS_GENERATED: "Tagi wygenerowane",
  CHAT_GPT_TAGS_GENERATION_ERROR: "Bład przy generowaniu tagów",
  INSTAGRAM_HASHTAGS_UPDATED: "Zaktualizowano hashtagi",
  INSTAGRAM_HASHTAGS_UPDATE_WARNING: "Błąd aktualizacji hashtagów",
  INSTAGRAM_TAG_DELETED: "Usunięto tag",
  INSTAGRAM_TAG_DELETE_WARNING: "Błąd usuwania tagu",
  INSTAGRAM_POST_CAPTION_UPDATED: "Zaktualizowano opis posta",
  INSTAGRAM_POST_CAPTION_UPDATE_WARNING: "Błąd aktualizacji opisu posta",
  INSTAGRAM_IMAGE_PROMPT_UPDATED: "Zaktualizowano image prompt",
  INSTAGRAM_IMAGE_UPDATE_WARNING: "Błąd image update",
  INSTAGRAM_POST_IMAGE_PROMPT_UPDATED: "Zaktualizowano image prompt posta",
  INSTAGRAM_POST_IMAGE_PROMPT_UPDATE_WARNING:
    "Błąd aktualizacji image prompt posta",
  INSTAGRAM_POST_TIME_ADDED: "Godziny publikacji zostały dodane pomyślnie.",
  INSTAGRAM_POST_TIME_UPDATED:
    "Godziny publikacji zostały zaktualizowane pomyślnie.",
  INSTAGRAM_POST_TIME_DELETED: "Godziny publikacji zostały usunięte pomyślnie.",
  INSTAGRAM_POST_TIME_ADD_WARNING:
    "Wystąpił problem podczas dodawania godzin publikacji.",
  INSTAGRAM_POST_TIME_UPDATE_WARNING:
    "Wystąpił problem podczas aktualizowania godzin publikacji.",
  INSTAGRAM_POST_TIME_DELETE_WARNING:
    "Wystąpił problem podczas usuwania godzin publikacji.",
  INSTAGRAM_POST_TIMES_FETCH_WARNING:
    "Wystąpił problem podczas pobierania godzin publikacji.",
  INSTAGRAM_POSTS_SCHEDULED: "Posty na Instagramie zostały zaplanowane",
  INSTAGRAM_POSTS_SCHEDULE_WARNING:
    "Posty na Instagramie nie zostały zaplanowane",
  INSTAGRAM_DATES_SWAPPED: "Zmieniono daty publikacji",
  INSTAGRAM_DATES_SWAP_WARNING: "Błąd zmiany dat",
  INSTAGRAM_POST_UPDATED: "Data publikacji została zaktualizowana",
  INSTAGRAM_POST_UPDATE_FAILED: "Data publikacji nie została zaktualizowana",
};

export default NOTIFY_TYPE;
