import { refreshInstagramAccount } from "shared/connectors/instagramConnector";
import { INSTAGRAM } from "store/types";
import { NOTIFY_TYPE, NOTIFY_LABEL } from "shared/consts";
import { Notify } from "shared/components";
import { toast } from "react-toastify";

const init = () => ({
  type: INSTAGRAM.REFRESH_ACCOUNT_INSTAGRAM_INIT,
});

export const success = (data) => ({
  type: INSTAGRAM.REFRESH_ACCOUNT_INSTAGRAM_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: INSTAGRAM.REFRESH_ACCOUNT_INSTAGRAM_FAILURE,
});

const refreshInstagramAccountAction = (userId) => async (dispatch) => {
  dispatch(init());
  try {
    const response = await refreshInstagramAccount(userId);
    dispatch(success(response.data));
    toast(
      <Notify
        label={NOTIFY_LABEL.INSTAGRAM_ACCOUNT_UPDATED}
        type={NOTIFY_TYPE.CHECKED}
        color="green"
      />
    );
    return response.data;
  } catch (err) {
    dispatch(failure());
    toast(
      <Notify
        label={NOTIFY_LABEL.INSTAGRAM_ACCOUNT_UPDATE_WARNING}
        type={NOTIFY_TYPE.WARNING}
        color="red"
      />
    );
    return err.response.data;
  }
};

export default refreshInstagramAccountAction;
