/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalTitle,
  Button,
  RegularField,
  SVG,
} from "shared/components";
import { Formik, Field } from "formik";
import { func, object } from "prop-types";
import { SVG_TYPE } from "shared/consts";

const AddTags = ({
  manageModal,
  setManageModal,
  generateInstagramTags,
  updateInstagramHashtags,
  instagramDetails,
  getInstagramDetails,
  getInstagramTagsByUser,
  instagramTags,
  deleteInstagramTag,
}) => {
  const closeModal = () => {
    setManageModal({ isModalOpen: false, user_id: manageModal.user_id });
    setGenerateOrAddNew("");
  };
  const [generateOrAddNew, setGenerateOrAddNew] = useState("");
  const isOpen = manageModal.isModalOpen === "postTags";
  const handleOnSubmit = async ({ message }) => {
    await generateInstagramTags(message, manageModal.user_id);
    await getInstagramTagsByUser(manageModal.user_id);
  };

  const handleSaveTagIdeas = async (values) => {
    await updateInstagramHashtags(manageModal.user_id, values);
  };

  const initialValues = {
    message: instagramDetails.hashtags || "",
  };

  useEffect(() => {
    if (isOpen) {
      getInstagramDetails(manageModal.user_id);
      getInstagramTagsByUser(manageModal.user_id);
    }
  }, [isOpen]);

  return (
    <>
      <Modal
        className="instagram-tags"
        opened={isOpen}
        onClose={() => closeModal()}
      >
        <div className="user-type-modal">
          <ul className="user-type-modal--left">
            <Formik
              onSubmit={handleOnSubmit}
              initialValues={initialValues}
              enableReinitialize
            >
              {({ handleSubmit, errors, values }) => {
                return (
                  <>
                    <ModalTitle title={`Tags ${manageModal.username}`} />
                    <div
                      className="instagram-tag__close"
                      onClick={() => closeModal()}
                    >
                      <SVG type={SVG_TYPE.CLOSE} />
                    </div>
                    <div className="instagram-tags">
                      {instagramTags.length > 0 &&
                        instagramTags.map((item) => {
                          return (
                            <div className="instagram-tag">
                              {item.label}
                              <div
                                className="instagram-tag__delete hidden"
                                onClick={() => deleteInstagramTag(item.id)}
                              >
                                <SVG type={SVG_TYPE.BIN} />
                              </div>
                            </div>
                          );
                        })}
                      <div className="instagram-empty-tag">
                        <div className="instagram-empty-tag__top">
                          {instagramTags.length === 0 && "Nie ma jeszcze tagów"}
                        </div>
                        {generateOrAddNew === "" && (
                          <div className="instagram-empty-tag__bottom">
                            <Button
                              label="Wygeneruj nowe"
                              className="btn--frame-dark"
                              onClick={() => setGenerateOrAddNew("generate")}
                            />
                            lub
                            <Button
                              label="Dodaj ręcznie"
                              className="btn--frame-dark"
                              onClick={() => setGenerateOrAddNew("addNew")}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <>
                      {generateOrAddNew === "generate" && (
                        <>
                          <Field
                            component={RegularField}
                            errors={errors}
                            label="Wpisz zapytanie o generowanie tagów"
                            name="message"
                            type="textarea"
                            customBorder="customize-field"
                          />
                          <div className="user-type-modal__buttons">
                            <Button
                              label="Anuluj"
                              className="btn--frame-dark"
                              onClick={() => setGenerateOrAddNew("")}
                            />
                            <Button
                              label="Zapisz"
                              className="btn--frame-dark"
                              onClick={() => handleSaveTagIdeas(values.message)}
                            />
                            <Button
                              label="generuj"
                              className="btn--normal"
                              onClick={() => handleSubmit()}
                            />
                          </div>
                        </>
                      )}
                    </>
                  </>
                );
              }}
            </Formik>
          </ul>
        </div>
      </Modal>
    </>
  );
};

export default AddTags;

AddTags.propTypes = {
  setManageModal: func.isRequired,
  updateInstagramPostTag: func.isRequired,
  generateInstagramTags: func.isRequired,
  manageModal: object.isRequired,
  instagramDetails: object.isRequired,
  getInstagramDetails: func.isRequired,
  deleteInstagramTag: func.isRequired,
};
