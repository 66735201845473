/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { SVG_TYPE } from "shared/consts";
import { SVG, Button, RegularField } from "shared/components";
import { object, func, bool } from "prop-types";
import {
  GeneratorModal,
  ReadyToUpload,
  ScheduledInstagramPost,
} from "screens/profile-page/components";
import { Field, Formik } from "formik";

const InstagramPostIdeas = ({
  manageModal,
  profile,
  setManageModal,
  isActive,
  updateInstagramIdeas,
  instagramDetails,
  generateChatGptResponse,
  instagramPostIdea,
  getInstagramPostIdea,
  updateInstagramPostIdea,
  deleteInstagramPostIdea,
  updateInstagramPostCaption,
  updateInstagramPostImagePromptForAi,
  updateInstagramPostStatus,
  getInstagramPostTimes,
  deleteInstagramPostTime,
  updateInstagramPostTime,
  addInstagramPostTime,
  instagramPostTime,
  scheduleInstagramPosts,
  swapScheduledDates,
  updateScheduledPostDate,
}) => {
  const [editManage, setEditManage] = useState({ isOpen: false, index: null });
  const [isOpen, setIsOpen] = useState(false);
  const [isEditPost, setIsEditPost] = useState(null);
  const [generatedImagePrompt, setGeneratedImagePrompt] = useState("");
  const [editImagePromptManage, setEditImagePromptManage] = useState({
    isOpen: false,
    index: null,
  });

  const [generatedCaption, setGenerateCaption] = useState("");
  const [whichStatusIsSelected, setWhichStatusIsSelected] =
    useState("generated");
  const isStatusAccepted = whichStatusIsSelected === "accepted";
  const isStatusGenerated = whichStatusIsSelected === "generated";
  const isStatusReadyToUpload = whichStatusIsSelected === "readyToUpload";
  const isStatusScheduled = whichStatusIsSelected === "scheduled";

  const getPosts = () => {
    getInstagramPostIdea(profile.user_id, whichStatusIsSelected);
  };

  const handleChangeStatus = (status) => {
    setWhichStatusIsSelected(status);
  };
  const handleUpdateStatus = async (postId, status) => {
    await updateInstagramPostStatus(postId, status);
    setWhichStatusIsSelected("readyToUpload");
  };

  useEffect(() => {
    isActive && getInstagramPostIdea(profile.user_id, whichStatusIsSelected);
  }, [isActive, whichStatusIsSelected]);

  useEffect(() => {
    isActive && getPosts();
  }, [getInstagramPostTimes, isActive]);

  const handleEditPost = async (values) => {
    await updateInstagramPostIdea(values.postId, values);
    setIsEditPost(false);
  };

  const handleDeletePost = async (postId) => {
    await deleteInstagramPostIdea(postId);
  };

  const handleAcceptedPost = async (postId) => {
    await updateInstagramPostIdea(postId, { status: "accepted", remove: true });
  };

  const handleRefreshCaption = async (postId, index) => {
    try {
      const response = await updateInstagramPostCaption(
        postId,
        "",
        "generateCaption"
      );
      setEditManage({ isOpen: true, index: index });
      setGenerateCaption(response);
    } catch (err) {
      console.error("Błąd przy generowaniu opisu:", err);
    }
  };

  const handleEditCaption = async (values) => {
    await updateInstagramPostCaption(
      values.postId,
      values.caption,
      "editImage"
    );
    setEditManage({ isOpen: false, index: null });
  };

  // Funkcja edycji imagePrompt
  const handleEditImagePrompt = async (values) => {
    await updateInstagramPostImagePromptForAi(
      values.postId,
      values.imagePrompt,
      "editImagePrompt"
    );
    setEditImagePromptManage({ isOpen: false, index: null });
  };

  // Funkcja generowania imagePrompt
  const handleRefreshImagePrompt = async (postId, index) => {
    const response = await updateInstagramPostImagePromptForAi(
      postId,
      "",
      "generateImagePrompt"
    );
    setEditImagePromptManage({ isOpen: true, index });
    setGeneratedImagePrompt(response);
  };

  return (
    <>
      <GeneratorModal
        getInstagramPostIdea={getInstagramPostIdea}
        instagramDetails={instagramDetails}
        userId={profile.user_id}
        updateInstagramIdeas={updateInstagramIdeas}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        generateChatGptResponse={generateChatGptResponse}
      />
      {isActive && (
        <div className={`instagramPostIdeas`}>
          <div
            className="instagramPostIdeas__close"
            onClick={() => setManageModal({ isModalOpen: false })}
          >
            <SVG type={SVG_TYPE.CLOSE} />
          </div>
          <div className="instagramPostIdeas__badges">
            <div
              className={`instagramPostIdeas__badge ${
                whichStatusIsSelected === "generated" ? "active" : ""
              }`}
              onClick={() => handleChangeStatus("generated")}
            >
              Pomysły
            </div>
            <div
              className={`instagramPostIdeas__badge ${
                whichStatusIsSelected === "accepted" ? "active" : ""
              }`}
              onClick={() => handleChangeStatus("accepted")}
            >
              Uzupełnianie pomysłów
            </div>
            <div
              className={`instagramPostIdeas__badge ${
                whichStatusIsSelected === "readyToUpload" ? "active" : ""
              }`}
              onClick={() => handleChangeStatus("readyToUpload")}
            >
              Gotowe do wgrania
            </div>
            <div
              className={`instagramPostIdeas__badge ${
                whichStatusIsSelected === "scheduled" ? "active" : ""
              }`}
              onClick={() => handleChangeStatus("scheduled")}
            >
              Wgrywane
            </div>
          </div>
          <div className="instagramPostIdeas__ideas">
            {isStatusAccepted && (
              <div className="instagramPostIdeasAccepted__heading">
                <div className="item lp">L.p</div>
                <div className="item">Pomysł</div>
                <div className="item tags">Tagi</div>
                <div className="item">opis</div>
                <div className="item">Zapytanie o grafikę</div>
                <div className="item">Grafika</div>
              </div>
            )}
            {instagramPostIdea.map((idea, index) => {
              const handleClickEdit = (index) => {
                if (index === isEditPost) {
                  setIsEditPost(null);
                } else {
                  setIsEditPost(index);
                }
              };
              const isEditCaption =
                editManage.isOpen && editManage.index === index;
              const isEdit = isEditPost === index;
              return (
                <>
                  {isStatusGenerated && (
                    <div
                      key={index}
                      className={`instagramPostIdeas__idea ${
                        isEdit ? "edit" : ""
                      }`}
                    >
                      {isEdit ? (
                        <>
                          <div
                            className="instagramPostIdeas__idea-edit close visible"
                            onClick={() => setIsEditPost(null)}
                          >
                            <SVG type={SVG_TYPE.CLOSE} />
                          </div>
                          <div className="instagramPostIdeas__idea--name">
                            <div className="instagramPostIdeas__idea-number">
                              {index + 1}
                            </div>
                            <Formik
                              initialValues={{
                                text: idea.text,
                                postId: idea.id,
                              }}
                              onSubmit={handleEditPost}
                            >
                              {({ handleSubmit, errors, values }) => (
                                <form onSubmit={handleSubmit}>
                                  <Field
                                    component={RegularField}
                                    errors={errors}
                                    name="text"
                                  />
                                  <div
                                    className="instagramPostIdeas__idea-edit visible"
                                    onClick={() => handleEditPost(values)}
                                  >
                                    <SVG type={SVG_TYPE.CHECK} />
                                  </div>
                                </form>
                              )}
                            </Formik>
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className="instagramPostIdeas__idea-edit accept hover"
                            onClick={() => handleAcceptedPost(idea.id)}
                          >
                            <SVG type={SVG_TYPE.PLUS_CIRCLE} />
                          </div>
                          <div
                            className="instagramPostIdeas__idea-edit remove hover"
                            onClick={() => handleDeletePost(idea.id)}
                          >
                            <SVG type={SVG_TYPE.BIN} />
                          </div>
                          <div
                            className="instagramPostIdeas__idea-edit hover"
                            onClick={() => handleClickEdit(index)}
                          >
                            <SVG type={SVG_TYPE.EDIT} />
                          </div>
                          <div className="instagramPostIdeas__idea--name">
                            <div className="instagramPostIdeas__idea-number">
                              {index + 1}
                            </div>
                            <div className="instagramPostIdeas__idea-label">
                              {idea.text}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                  {isStatusAccepted && (
                    <div className="instagramPostIdeasAccepted__list">
                      <div className="item lp">
                        {index + 1}
                        <div
                          className="remove"
                          onClick={() => handleDeletePost(idea.id)}
                        >
                          <SVG type={SVG_TYPE.BIN} />
                        </div>
                        <div
                          onClick={() =>
                            handleUpdateStatus(idea.id, "readyToUpload")
                          }
                        >
                          <SVG type={SVG_TYPE.PLUS_CIRCLE} />
                        </div>
                      </div>
                      <div className="item">{idea.text}</div>
                      <div className="item tags">
                        {idea.tags &&
                          idea.tags.length > 0 &&
                          idea.tags.map((item) => <span>{item}</span>)}
                      </div>
                      <div
                        className={`item idea-caption ${
                          isEditCaption ? "idea-caption--edit" : ""
                        }`}
                      >
                        {isEditCaption && (
                          <>
                            <Formik
                              initialValues={{
                                caption: generatedCaption
                                  ? generatedCaption
                                  : idea.caption,
                                postId: idea.id,
                              }}
                              enableReinitialize
                              onSubmit={handleEditCaption}
                            >
                              {({ handleSubmit, errors, values }) => {
                                return (
                                  <form
                                    className="idea-caption__form"
                                    onSubmit={handleSubmit}
                                  >
                                    <Field
                                      component={RegularField}
                                      errors={errors}
                                      type="textarea"
                                      name="caption"
                                    />
                                    <div
                                      className="instagramPostIdeas__idea-edit visible"
                                      onClick={() => handleEditCaption(values)}
                                    >
                                      <SVG type={SVG_TYPE.CHECK} />
                                    </div>
                                    <div
                                      className="instagramPostIdeas__idea-edit visible visible2"
                                      onClick={() =>
                                        setEditManage({
                                          isOpen: false,
                                          index: null,
                                        })
                                      }
                                    >
                                      <SVG type={SVG_TYPE.CLOSE} />
                                    </div>
                                  </form>
                                );
                              }}
                            </Formik>
                          </>
                        )}
                        {idea.caption}
                        <div className="idea-caption__actions">
                          <div
                            className="idea-caption__item hover"
                            onClick={() => handleRefreshCaption(idea.id, index)}
                          >
                            <SVG type={SVG_TYPE.REFRESH} />
                          </div>
                          <div
                            className="idea-caption__item idea-caption__edit hover"
                            onClick={() =>
                              setEditManage({
                                isOpen: true,
                                index,
                                postId: idea.id,
                              })
                            }
                          >
                            <SVG type={SVG_TYPE.EDIT} />
                          </div>
                        </div>
                      </div>
                      <div
                        className={`item idea-caption  ${
                          editImagePromptManage.isOpen &&
                          editImagePromptManage.index === index
                            ? "idea-caption--edit"
                            : ""
                        }`}
                      >
                        {editImagePromptManage.isOpen &&
                        editImagePromptManage.index === index ? (
                          <>
                            <Formik
                              initialValues={{
                                imagePrompt: generatedImagePrompt
                                  ? generatedImagePrompt
                                  : idea.image_prompt,
                                postId: idea.id,
                              }}
                              enableReinitialize
                              onSubmit={handleEditImagePrompt}
                            >
                              {({ handleSubmit, errors, values }) => (
                                <form
                                  className="idea-caption__form"
                                  onSubmit={handleSubmit}
                                >
                                  <Field
                                    component={RegularField}
                                    errors={errors}
                                    type="textarea"
                                    name="imagePrompt"
                                  />
                                  <div
                                    className="instagramPostIdeas__idea-edit visible"
                                    onClick={() =>
                                      handleEditImagePrompt(values)
                                    }
                                  >
                                    <SVG type={SVG_TYPE.CHECK} />
                                  </div>
                                  <div
                                    className="instagramPostIdeas__idea-edit visible visible2"
                                    onClick={() => {
                                      setEditImagePromptManage({
                                        isOpen: false,
                                        index: null,
                                      });
                                      setGeneratedImagePrompt("");
                                    }}
                                  >
                                    <SVG type={SVG_TYPE.CLOSE} />
                                  </div>
                                </form>
                              )}
                            </Formik>
                          </>
                        ) : (
                          idea.image_prompt
                        )}
                        <div className="idea-caption__actions">
                          <div
                            className="idea-caption__item hover"
                            onClick={() =>
                              handleRefreshImagePrompt(idea.id, index)
                            }
                          >
                            <SVG type={SVG_TYPE.REFRESH} />
                          </div>
                          <div
                            className="idea-caption__item idea-caption__edit hover"
                            onClick={() =>
                              setEditImagePromptManage({
                                isOpen: true,
                                index,
                                postId: idea.id,
                              })
                            }
                          >
                            <SVG type={SVG_TYPE.EDIT} />
                          </div>
                        </div>
                      </div>
                      <div className="item idea-image">
                        <img src={idea.image_url} alt="" />
                      </div>
                    </div>
                  )}
                </>
              );
            })}
            {isStatusReadyToUpload && (
              <ReadyToUpload
                scheduleInstagramPosts={scheduleInstagramPosts}
                instagramPostTime={instagramPostTime}
                instagramPostIdea={instagramPostIdea}
                deleteInstagramPostTime={deleteInstagramPostTime}
                updateInstagramPostTime={updateInstagramPostTime}
                addInstagramPostTime={addInstagramPostTime}
                userId={profile.user_id}
              />
            )}
            {isStatusScheduled && (
              <ScheduledInstagramPost
                swapScheduledDates={swapScheduledDates}
                userId={profile.user_id}
                instagramPostIdea={instagramPostIdea}
                updateScheduledPostDate={updateScheduledPostDate}
                getPosts={getPosts}
              />
            )}
          </div>
          {whichStatusIsSelected === "generated" && (
            <Button
              label="Generuj pomysły"
              className="btn--frame-dark"
              onClick={() => setIsOpen(true)}
            />
          )}
        </div>
      )}
    </>
  );
};

export default InstagramPostIdeas;

InstagramPostIdeas.propTypes = {
  manageModal: func.isRequired,
  getInstagramDetails: func.isRequired,
  updateInstagramIdeas: func.isRequired,
  setManageModal: func.isRequired,
  generateChatGptResponse: func.isRequired,
  updateInstagramPostIdea: func.isRequired,
  deleteInstagramPostIdea: func.isRequired,
  updateInstagramPostCaption: func.isRequired,
  updateInstagramPostImagePromptForAi: func.isRequired,
  updateInstagramPostStatus: func.isRequired,
  profile: object.isRequired,
  instagramDetails: object.isRequired,
  instagramPostTime: object.isRequired,
  isActive: bool.isRequired,
  getInstagramPostTimes: func.isRequired,
  updateScheduledPostDate: func.isRequired,
  swapScheduledDates: func.isRequired,
  deleteInstagramPostTime: func.isRequired,
  updateInstagramPostTime: func.isRequired,
  addInstagramPostTime: func.isRequired,
  scheduleInstagramPosts: func.isRequired,
};
