import { updateInstagramPostTime as updateInstagramPostTimeConnector } from "shared/connectors/instagramConnector";
import { NOTIFY_TYPE, NOTIFY_LABEL } from "shared/consts";
import { Notify } from "shared/components";
import { toast } from "react-toastify";
import { INSTAGRAM } from "store/types";

const init = () => ({
  type: INSTAGRAM.UPDATE_INSTAGRAM_POST_TIME_INIT,
});

const success = (data) => ({
  type: INSTAGRAM.UPDATE_INSTAGRAM_POST_TIME_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: INSTAGRAM.UPDATE_INSTAGRAM_POST_TIME_FAILURE,
});

const updateInstagramPostTime =
  (postTimeId, updatedData) => async (dispatch) => {
    dispatch(init());
    try {
      const response = await updateInstagramPostTimeConnector(
        postTimeId,
        updatedData
      );
      dispatch(success(response.data));
      toast(
        <Notify
          label={NOTIFY_LABEL.INSTAGRAM_POST_TIME_UPDATED}
          type={NOTIFY_TYPE.CHECKED}
          color="green"
        />
      );
      return response.data;
    } catch (err) {
      dispatch(failure());
      toast(
        <Notify
          label={NOTIFY_LABEL.INSTAGRAM_POST_TIME_UPDATE_WARNING}
          type={NOTIFY_TYPE.WARNING}
          color="red"
        />
      );
      return err.response ? err.response.data : err.message;
    }
  };

export default updateInstagramPostTime;
