import { getInstagramPostTimes as getInstagramPostTimesConnector } from "shared/connectors/instagramConnector";
import { NOTIFY_TYPE, NOTIFY_LABEL } from "shared/consts";
import { Notify } from "shared/components";
import { toast } from "react-toastify";
import { INSTAGRAM } from "store/types";

const init = () => ({
  type: INSTAGRAM.GET_INSTAGRAM_POST_TIMES_INIT,
});

const success = (data) => ({
  type: INSTAGRAM.GET_INSTAGRAM_POST_TIMES_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: INSTAGRAM.GET_INSTAGRAM_POST_TIMES_FAILURE,
});

const getInstagramPostTimes = (userId) => async (dispatch) => {
  dispatch(init());
  try {
    const response = await getInstagramPostTimesConnector(userId);
    dispatch(success(response.data.data));
    return response.data.data;
  } catch (err) {
    dispatch(failure());
    toast(
      <Notify
        label={NOTIFY_LABEL.INSTAGRAM_POST_TIMES_FETCH_WARNING}
        type={NOTIFY_TYPE.WARNING}
        color="red"
      />
    );
    return err.response ? err.response.data : err.message;
  }
};

export default getInstagramPostTimes;
