import { deleteInstagramPostIdea as deleteInstagramPostIdeaConnector } from "shared/connectors/instagramConnector";
import { NOTIFY_TYPE, NOTIFY_LABEL } from "shared/consts";
import { Notify } from "shared/components";
import { toast } from "react-toastify";
import { INSTAGRAM } from "store/types";

const init = () => ({
  type: INSTAGRAM.DELETE_INSTAGRAM_POST_IDEA_INIT,
});

const success = (postId) => ({
  type: INSTAGRAM.DELETE_INSTAGRAM_POST_IDEA_SUCCESS,
  payload: {
    postId,
  },
});

const failure = () => ({
  type: INSTAGRAM.DELETE_INSTAGRAM_POST_IDEA_FAILURE,
});

const deleteInstagramPostIdea = (postId) => async (dispatch) => {
  dispatch(init());
  try {
    await deleteInstagramPostIdeaConnector(postId);
    dispatch(success(postId));
    toast(
      <Notify
        label={NOTIFY_LABEL.INSTAGRAM_POST_IDEA_DELETED}
        type={NOTIFY_TYPE.CHECKED}
        color="green"
      />
    );
    return true;
  } catch (err) {
    dispatch(failure());
    toast(
      <Notify
        label={NOTIFY_LABEL.INSTAGRAM_POST_IDEA_DELETE_WARNING}
        type={NOTIFY_TYPE.WARNING}
        color="red"
      />
    );
    return err.response ? err.response.data : err.message;
  }
};

export default deleteInstagramPostIdea;
