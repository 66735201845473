import { swapScheduledDates as swapScheduledDatesConnector } from "shared/connectors/instagramConnector"; // Funkcja API do zamiany dat
import { INSTAGRAM } from "store/types";
import { NOTIFY_TYPE, NOTIFY_LABEL } from "shared/consts";
import { Notify } from "shared/components";
import { toast } from "react-toastify";

const initSwap = () => ({
  type: INSTAGRAM.SWAP_SCHEDULED_DATES_INIT,
});

export const swapSuccess = (data) => ({
  type: INSTAGRAM.SWAP_SCHEDULED_DATES_SUCCESS,
  payload: {
    data,
  },
});

const swapFailure = () => ({
  type: INSTAGRAM.SWAP_SCHEDULED_DATES_FAILURE,
});

const swapScheduledDates = (currentId, previousId) => async (dispatch) => {
  dispatch(initSwap());
  try {
    const response = await swapScheduledDatesConnector(currentId, previousId);

    dispatch(swapSuccess({ current_id: currentId, previous_id: previousId }));

    toast(
      <Notify
        label={NOTIFY_LABEL.INSTAGRAM_DATES_SWAPPED}
        type={NOTIFY_TYPE.CHECKED}
        color="green"
      />
    );

    return response.data;
  } catch (err) {
    dispatch(swapFailure());
    toast(
      <Notify
        label={NOTIFY_LABEL.INSTAGRAM_DATES_SWAP_WARNING}
        type={NOTIFY_TYPE.WARNING}
        color="red"
      />
    );

    return err.response ? err.response.data : { error: "Unknown error" };
  }
};

export default swapScheduledDates;
