import React, { useEffect, useState } from "react";
import { bool, func, object } from "prop-types";
import { DashboardContent } from "screens/dashboard/components";
import { Button } from "shared/components";
import {
  AddProfile,
  InstagramProfile,
  InstagramPostIdeas,
  AddTags,
  ImagePromptModal,
} from "./components/";
import { SVG_TYPE } from "shared/consts";

const ProfilePage = ({
  authorizedUser,
  allAccounts,
  instagramDetails,
  chatGptResponse,
  instagramPostIdea,
  instagramTags,
  instagramPostTime,
  actions: {
    getAuthorizedUser,
    addInstagramAccountAction,
    getAllInstagramAccounts,
    refreshInstagramAccountAction,
    getInstagramDetails,
    updateInstagramIdeas,
    generateChatGptResponse,
    getInstagramPostIdea,
    updateInstagramPostIdea,
    deleteInstagramPostIdea,
    generateInstagramTags,
    updateInstagramHashtags,
    getInstagramTagsByUser,
    deleteInstagramTag,
    updateInstagramPostCaption,
    updateInstagramImagePrompt,
    updateInstagramPostImagePromptForAi,
    updateInstagramPostStatus,
    getInstagramPostTimes,
    deleteInstagramPostTime,
    updateInstagramPostTime,
    addInstagramPostTime,
    scheduleInstagramPosts,
    swapScheduledDates,
    updateScheduledPostDate,
  },
}) => {
  const [manageModal, setManageModal] = useState({ isModalOpen: false });

  const openModal = (which, userId = false, biography = false) => {
    setManageModal({
      isModalOpen: which,
      userid: userId,
      biography: biography,
    });
  };

  useEffect(() => {
    !authorizedUser && getAuthorizedUser();
  }, [getAuthorizedUser, authorizedUser]);

  useEffect(() => {
    getAllInstagramAccounts();
  }, [getAllInstagramAccounts]);

  return (
    <>
      <DashboardContent name="Instagramowe profile">
        <AddProfile
          addInstagramAccountAction={addInstagramAccountAction}
          manageModal={manageModal}
          setManageModal={setManageModal}
        />
        <AddTags
          deleteInstagramTag={deleteInstagramTag}
          instagramTags={instagramTags}
          manageModal={manageModal}
          setManageModal={setManageModal}
          generateInstagramTags={generateInstagramTags}
          updateInstagramHashtags={updateInstagramHashtags}
          instagramDetails={instagramDetails}
          getInstagramDetails={getInstagramDetails}
          getInstagramTagsByUser={getInstagramTagsByUser}
        />
        <ImagePromptModal
          instagramDetails={instagramDetails}
          manageModal={manageModal}
          setManageModal={setManageModal}
          updateInstagramImagePrompt={updateInstagramImagePrompt}
          getInstagramDetails={getInstagramDetails}
        />
        <div className="instagram-profiles">
          {allAccounts &&
            allAccounts.length > 0 &&
            allAccounts.map((item) => {
              const isActive =
                item.user_id === manageModal.user_id &&
                manageModal?.isModalOpen === "postIdeas";
              return (
                <>
                  <InstagramProfile
                    getInstagramDetails={getInstagramDetails}
                    isActive={isActive}
                    setManageModal={setManageModal}
                    profile={item}
                    refreshInstagramAccountAction={
                      refreshInstagramAccountAction
                    }
                  />
                  <InstagramPostIdeas
                    updateScheduledPostDate={updateScheduledPostDate}
                    scheduleInstagramPosts={scheduleInstagramPosts}
                    instagramPostTime={instagramPostTime}
                    updateInstagramPostImagePromptForAi={
                      updateInstagramPostImagePromptForAi
                    }
                    swapScheduledDates={swapScheduledDates}
                    getInstagramPostTimes={getInstagramPostTimes}
                    deleteInstagramPostTime={deleteInstagramPostTime}
                    updateInstagramPostTime={updateInstagramPostTime}
                    addInstagramPostTime={addInstagramPostTime}
                    updateInstagramPostStatus={updateInstagramPostStatus}
                    updateInstagramPostCaption={updateInstagramPostCaption}
                    deleteInstagramPostIdea={deleteInstagramPostIdea}
                    updateInstagramPostIdea={updateInstagramPostIdea}
                    instagramPostIdea={instagramPostIdea}
                    getInstagramPostIdea={getInstagramPostIdea}
                    generateChatGptResponse={generateChatGptResponse}
                    instagramDetails={instagramDetails}
                    updateInstagramIdeas={updateInstagramIdeas}
                    isActive={isActive}
                    manageModal={manageModal}
                    setManageModal={setManageModal}
                    profile={item}
                  />
                </>
              );
            })}
        </div>
        <Button
          label="Dodaj kolejne konto"
          iconPlacement="left"
          iconType={SVG_TYPE.PLUS}
          className="btn--frame-dark instagram-adding"
          onClick={() => openModal("adding")}
        />
      </DashboardContent>
    </>
  );
};
export default ProfilePage;

ProfilePage.propTypes = {
  authorizedUser: object.isRequired,
  allAccounts: object.isRequired,
  instagramPostIdea: object.isRequired,
  instagramPostTime: object.isRequired,
  isMobile: bool.isRequired,
  isModalOpened: bool.isRequired,
  generateChatGptResponse: func.isRequired,
  updateInstagramHashtags: func.isRequired,
  updateInstagramPostIdea: func.isRequired,
  generateInstagramTags: func.isRequired,
  getInstagramPostIdea: func.isRequired,
  deleteInstagramPostIdea: func.isRequired,
  updateInstagramPostStatus: func.isRequired,
  getInstagramPostTimes: func.isRequired,
  deleteInstagramPostTime: func.isRequired,
  updateInstagramPostTime: func.isRequired,
  addInstagramPostTime: func.isRequired,
  updateScheduledPostDate: func.isRequired,
};
