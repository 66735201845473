/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Modal, ModalTitle, Button, RegularField } from "shared/components";
import { Formik, Field } from "formik";
import { func, object, string } from "prop-types";

const ImagePromptModal = ({
  manageModal,
  setManageModal,
  updateInstagramImagePrompt,
  instagramDetails,
  getInstagramDetails,
}) => {
  const closeModal = () => {
    setManageModal({ isModalOpen: false, user_id: manageModal.user_id });
  };
  const isOpen = manageModal.isModalOpen === "imagePrompt";

  const handleOnSubmit = async (values) => {
    console.log(values);
  };
  const handleSaveImagePrompt = async (image) => {
    await updateInstagramImagePrompt(manageModal.user_id, image);
    closeModal();
  };

  useEffect(() => {
    isOpen && getInstagramDetails(manageModal.user_id);
  }, [isOpen]);

  return (
    <>
      <Modal
        className="instagram-bio-edit"
        opened={isOpen}
        onClose={() => closeModal()}
      >
        <div className="user-type-modal">
          <ul className="user-type-modal--left">
            <Formik
              onSubmit={handleOnSubmit}
              initialValues={{
                image: instagramDetails.image || "Jeszcze nie ma opisu",
              }}
              enableReinitialize
            >
              {({ handleSubmit, errors, values }) => (
                <>
                  <ModalTitle title={`Style generowanego obrazu`} />
                  <>
                    <Field
                      component={RegularField}
                      errors={errors}
                      name="image"
                      type="textarea"
                      customBorder="customize-field"
                    />
                    <div className="user-type-modal__buttons">
                      <Button
                        label="Anuluj"
                        className="btn--frame-dark"
                        onClick={() => closeModal()}
                      />
                      <Button
                        label="Zapisz"
                        className="btn--frame-dark"
                        onClick={() => handleSaveImagePrompt(values.image)}
                      />
                    </div>
                  </>
                </>
              )}
            </Formik>
          </ul>
        </div>
      </Modal>
    </>
  );
};

export default ImagePromptModal;

ImagePromptModal.propTypes = {
  setManageModal: func.isRequired,
  updateInstagramImagePrompt: func.isRequired,
  instagramDetails: object.isRequired,
  setAccountType: func.isRequired,
  getInstagramPostIdea: func.isRequired,
  userId: string.isRequired,
};
