import React, { useState } from "react";
import { string, func } from "prop-types";
import moment from "moment";
import { SVG_TYPE } from "shared/consts";
import { SVG } from "shared/components";
import { Field, Formik } from "formik";

const ScheduledInstagramPost = ({
  instagramPostIdea,
  swapScheduledDates,
  updateScheduledPostDate,
  getPosts,
}) => {
  const [whichOneIsEdited, setWhichOneIsEdited] = useState({
    postid: "",
    scheduled: "",
  });

  const findClosestFuturePost = () => {
    const now = moment();
    let closestPost = null;

    instagramPostIdea.forEach((post) => {
      const postDate = moment(post.scheduled_at);
      if (
        postDate.isSameOrAfter(now) &&
        (!closestPost || postDate.isBefore(moment(closestPost.scheduled_at)))
      ) {
        closestPost = post;
      }
    });

    return closestPost;
  };

  const closestFuturePost = findClosestFuturePost();

  const handleClick = async (item, direction) => {
    const currentIndex = instagramPostIdea.findIndex(
      (post) => post.id === item.id
    );
    if (direction === "up") {
      const previousItem = instagramPostIdea[currentIndex - 1];
      if (previousItem) {
        await swapScheduledDates(item.id, previousItem.id);
      }
    } else if (direction === "down") {
      const nextItem = instagramPostIdea[currentIndex + 1];
      if (nextItem) {
        await swapScheduledDates(item.id, nextItem.id);
      }
    }
  };

  const handleOpenEditDate = (postId, scheduled) => {
    setWhichOneIsEdited({ postId, scheduled });
  };

  const submitForm = async (values) => {
    setWhichOneIsEdited({ postId: "", scheduled: "" });
    await updateScheduledPostDate(values.id, values.scheduled_at);
    await getPosts();
  };

  return (
    <div className="sip">
      <div className="sip__list">
        {instagramPostIdea &&
          instagramPostIdea.length > 0 &&
          instagramPostIdea.map((item, index) => {
            const isToday = moment(item.scheduled_at).isSame(moment(), "day");
            const isInFuture = moment(item.scheduled_at).isSameOrAfter(
              moment(),
              "minute"
            );
            const isPublicated = item.status === "published";
            const isError = item.status === "error";
            const isLastElement = index === instagramPostIdea.length - 1;
            const isEditDate = item.id === whichOneIsEdited.postId;

            return (
              <div className="sip__item-wrapper" key={item.id}>
                <div className="sip__item">
                  {isInFuture && (
                    <div className="sip__item-actions">
                      {!isToday && (
                        <>
                          {closestFuturePost &&
                            closestFuturePost.id &&
                            !(closestFuturePost.id === item.id) && (
                              <div
                                className={`sip__item-action sip__item-action--top`}
                                onClick={() => handleClick(item, "up")}
                              >
                                <SVG type={SVG_TYPE.CHEVRONE} />
                              </div>
                            )}
                        </>
                      )}
                      {!isLastElement && (
                        <div
                          className="sip__item-action"
                          onClick={() => handleClick(item, "down")}
                        >
                          <SVG type={SVG_TYPE.CHEVRONE} />
                        </div>
                      )}
                    </div>
                  )}
                  <div className="sip__item-image">
                    <img src={item.image_url} alt="" />
                  </div>
                  <div className="sip__item-heading">{item.caption}</div>
                  <div
                    className={`sip__item__date ${
                      isInFuture ? "sip__item__date--future" : ""
                    }`}
                  >
                    {isEditDate ? (
                      <>
                        <Formik
                          initialValues={{
                            id: item.id,
                            scheduled_at: moment(item.scheduled_at).format(
                              "DD/MM/YYYY HH:MM"
                            ),
                          }}
                          onSubmit={submitForm}
                        >
                          {({
                            handleSubmit,
                            errors,
                            setFieldValue,
                            setFieldTouched,
                            values,
                          }) => {
                            return (
                              <form onSubmit={handleSubmit}>
                                <Field name="scheduled_at" />
                                <div
                                  className="sip__item-edit"
                                  onClick={() => handleSubmit()}
                                >
                                  <SVG type={SVG_TYPE.EDIT} />
                                </div>
                              </form>
                            );
                          }}
                        </Formik>
                      </>
                    ) : (
                      <>
                        {moment(item.scheduled_at).format("DD/MM/YYYY HH:mm")}
                      </>
                    )}
                    {((closestFuturePost && closestFuturePost.id === item.id) ||
                      isInFuture) &&
                      !isEditDate && (
                        <div
                          className="sip__item-edit"
                          onClick={() =>
                            handleOpenEditDate(item.id, item.scheduled_at)
                          }
                        >
                          <SVG type={SVG_TYPE.EDIT} />
                        </div>
                      )}
                    <div
                      className={`sip__status ${
                        isPublicated ? "sip__status--publicated" : ""
                      } ${isError ? "sip__status--warning" : ""}`}
                    >
                      {isPublicated && <SVG type={SVG_TYPE.CHECK} />}
                      {isError && <SVG type={SVG_TYPE.WARNING} />}
                    </div>
                  </div>
                  {isToday && <div className="sip__item-today">Dzisiaj</div>}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

ScheduledInstagramPost.propTypes = {
  userId: string.isRequired,
  swapScheduledDates: func.isRequired,
  updateScheduledPostDate: func.isRequired,
};

export default ScheduledInstagramPost;
