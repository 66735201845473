import { useState } from "react";
import DatePicker from "react-datepicker";
import {
  number,
  func,
  shape,
  oneOfType,
  string,
  bool,
  instanceOf,
} from "prop-types";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { registerLocale } from "react-datepicker";
import pl from "date-fns/locale/pl";

// Rejestracja lokalizacji dla polskiego formatu
registerLocale("pl", pl);

const DatePickerField = ({
  field: { name, onChange, value },
  handleOnChange,
  label,
  form,
  onBlur,
  className,
  disabled,
  placeholder,
  errors,
  withHour,
  minDate,
}) => {
  const [startDate, setStartDate] = useState(value);
  const isError = form && !!(form.touched[name] && errors && errors[name]);

  return (
    <div className={`date-picker-field__wrapper ${className || ""}`}>
      {label && (
        <div
          className={`date-picker-field__label ${
            disabled ? "date-picker-field__label--disabled" : ""
          }`}
        >
          {label}
        </div>
      )}
      <DatePicker
        value={value}
        onBlur={onBlur}
        placeholder={placeholder}
        locale="pl"
        className={`date-picker-field ${
          isError ? "date-picker-field__input--error" : ""
        } ${className ? `date-picker-field__${className}` : ""}`}
        selected={startDate}
        onChange={(date) => {
          handleOnChange(moment(date).format("DD/MM/YYYY HH:mm"));
          setStartDate(date);
          onChange(date);
        }}
        showTimeSelect={withHour}
        timeFormat="HH:mm"
        timeIntervals={1}
        dateFormat={withHour ? "dd/MM/yyyy HH:mm" : "dd/MM/yyyy"}
        disabled={disabled}
        minDate={minDate}
        weekStartsOn={1}
      />
      {isError && (
        <div className="select-field__error-label">{errors[name]}</div>
      )}
    </div>
  );
};

export default DatePickerField;

DatePickerField.defaultProps = {
  disabled: false,
  string: "",
  withHour: false, // Domyślnie bez godzin
  minDate: null, // Domyślnie brak minimalnej daty
};

DatePickerField.propTypes = {
  field: shape({
    name: string,
    onChange: func,
    value: oneOfType([string, number]),
  }).isRequired,
  placeholder: string,
  onBlur: func.isRequired,
  disabled: bool,
  withHour: bool, // Nowy prop typ boolean
  minDate: instanceOf(Date), // Nowy prop typ `Date` dla minimalnej daty
};
